import React from 'react';
import styled from 'styled-components';
import ThemeType from '../../../types/Theme';

const RightOuter = styled.div(
  ({ theme: { mobileView } }: ThemeType) => `
    flex: 1;
    height: 100%;
    max-width: 50%;
    overflow: scroll;

    ::-webkit-scrollbar {
      width: 0px; /* remove scrollbar space */
      background: transparent; /* optional: just make scrollbar invisible */
    }

    ${mobileView} {
      max-width: none;
    }
`,
);

const RightInner = styled.div(
  ({ theme: { mobileView, screenWidths, spacing } }: ThemeType) => `
    max-width: ${screenWidths.tablet / 2}px;
    margin: 0 auto 0 0;
    padding-bottom: ${spacing.x16};

    ${mobileView} {
      padding-bottom: 0;
    }
`,
);

interface Props {
  id?: string;
  children?: React.ReactNode;
}
const Right: React.StatelessComponent<Props> = ({ children, id }) => (
  <RightOuter id={id}>
    <RightInner>{children}</RightInner>
  </RightOuter>
);

export default Right;
