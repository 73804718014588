import React from 'react';
import { ThemeProvider } from 'styled-components';
import CSSParallax from '../../components/CSSParallax';
import Header from '../../components/Header';
import MainMenu from '../../components/MainMenu';
import SearchMenu from '../../components/SearchMenu';
import SubscriptionMenu from '../../components/SubscriptionMenu';
import { MODALS, MENUS, PAGE_WRAP_ID } from '../../constants/layouts';
import GlobalStyle from '../../styles/GlobalStyle';
import theme from '../../styles/theme';
import AbstractLayout from './AbstractLayout';
import Head from './Head';
import MySplendourModal from '../../components/MySplendourModal';

class FixedLayout extends AbstractLayout {
  render() {
    const { showMenu } = this.state;
    const { seo, announcement, hideAnnouncement, headerMenu, mainMenu, headless, showModal } = this.props;

    return (
      <ThemeProvider theme={theme}>
        <CSSParallax.Layout natural={true}>
          <GlobalStyle />
          <Head {...seo} />
          {!headless && (
            <>
              <MainMenu
                isOpen={String(showMenu) === MENUS.MAIN}
                close={this.closeMenu}
                openSubscribeMenu={this.openSubscribeMenu}
                items={mainMenu && mainMenu.items}
              />
              <SearchMenu isOpen={String(showMenu) === MENUS.SEARCH} close={this.closeMenu} />
              <SubscriptionMenu isOpen={String(showMenu) === MENUS.SUBSCRIBE} close={this.closeMenu} />
              <Header
                openMainMenu={this.openMainMenu}
                openSearch={this.openSearchMenu}
                openSubscribeMenu={this.openSubscribeMenu}
                pageLinks={headerMenu && headerMenu.items}
                announcement={announcement}
                hideAnnouncement={hideAnnouncement}
                hidePrimaryHeader={true}
              />
            </>
          )}
          <CSSParallax.Page id={PAGE_WRAP_ID} natural={true}>
            {this.props.children}
          </CSSParallax.Page>
        </CSSParallax.Layout>
      </ThemeProvider>
    );
  }
}

export default FixedLayout;
