import React from 'react';
import { ArtistType } from '../../types';
import Artist from '../Artist';
import { scroller } from 'react-scroll';
import { InView } from 'react-intersection-observer';

class ScrollArtist extends React.PureComponent<ArtistType> {
  constructor(props) {
    super(props);
    this.handleInView = this.handleInView.bind(this);
  }

  componentDidMount() {
    this.doScroll();
  }

  componentDidUpdate() {
    this.doScroll();
  }

  handleInView(inView) {
    if (inView) {
      const artistLinks = document.getElementsByClassName('lineup-link-active');

      Array.prototype.forEach.call(artistLinks, (artistLink) => {
        artistLink.classList.remove('lineup-link-active');
      });

      const activeLink = document.getElementById(`Link-${this.props.wordpress_id}`);

      if (activeLink) {
        activeLink.classList.add('lineup-link-active');
      }

      window.history.pushState(null, '', `${this.props.slug}`);

      scroller.scrollTo(`Link-${this.props.wordpress_id}`, {
        offset: this.props.lineupOffset,
        duration: 500,
        containerId: 'links-container',
      });
    }
  }

  doScroll() {
    if (this.props.selected && this.props.containerId) {
      scroller.scrollTo(this.props.wordpress_id, {
        offset: this.props.artistOffset,
        align: 'top',
        duration: 500,
        containerId: this.props.containerId,
      });
    }
  }

  render() {
    return (
      <InView threshold={0.2} as="div" onChange={this.handleInView}>
        <Artist {...this.props} />
      </InView>
    );
  }
}

export default ScrollArtist;
