import React from 'react';
import SvgType from './type';
import StyledSvg from './style';

const ScrollHere: React.StatelessComponent<SvgType> = ({ color }) => (
  <StyledSvg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 364.85 282.454"
    enable-background="new 0 0 364.85 282.454"
    xmlSpace="preserve"
    color={color}
  >
    <path
      d="M258.732,80.108c-0.294-0.551-0.951-0.662-1.167-0.698c-0.185-0.031-0.361-0.05-0.523-0.056
				c-0.638-0.024-1.143,0.144-1.502,0.501c-0.184,0.183-0.323,0.412-0.416,0.683c-0.364-0.179-0.732-0.276-1.095-0.29
				c-1.02-0.039-1.885,0.569-2.374,1.669c-0.04,0.091-0.079,0.184-0.118,0.278c-0.257,0.617-0.427,0.971-0.92,1.09
				c-0.541,0.13-1.228,0.572-1.494,1.167c-1.297,2.905-2.433,5.575-3.473,8.163c-0.243,0.605-0.45,0.702-0.947,0.684
				c-0.249-0.009-0.498-0.018-0.747-0.028c-1.49-0.057-3.03-0.115-4.552-0.027c-1.95,0.113-3.916,0.302-5.818,0.485
				c-0.561,0.054-1.135,0.109-1.707,0.162c0.089-0.409,0.176-0.817,0.264-1.226c0.319-1.491,0.649-3.032,1.034-4.526
				c0.333-1.29,0.717-2.338,1.174-3.203l0.107-0.202c0.465-0.876,0.721-1.359,0.62-1.933c-0.098-0.56-0.515-0.967-1.205-1.626
				c-0.002-0.006-0.005-0.013-0.007-0.019c-0.032-0.081-0.081-0.203-0.17-0.321c-0.404-0.536-0.949-0.843-1.536-0.866
				c-0.734-0.028-1.409,0.398-1.805,1.139c-0.03,0.056-0.059,0.112-0.087,0.167l-0.841-0.685l-0.513-0.418l-0.404,0.524
				c-0.104,0.135-0.214,0.267-0.331,0.408c-0.287,0.344-0.584,0.699-0.808,1.124c-0.18,0.341-0.268,0.692-0.352,1.03
				c-0.056,0.223-0.108,0.434-0.182,0.616c-0.48,1.173-0.978,2.362-1.461,3.512c-0.426,1.016-0.866,2.066-1.293,3.102
				c-0.636,1.547-1.319,3.25-2.149,5.357c-0.322,0.817-0.695,1.787-1.023,2.782c-0.088,0.268-0.179,0.536-0.27,0.803
				c-0.36,1.063-0.732,2.162-0.969,3.289c-0.045,0.215-0.216,0.49-0.38,0.757c-0.365,0.59-1.015,1.641-0.096,2.613
				c-0.051,0.211-0.1,0.419-0.148,0.622c-0.063,0.267-0.125,0.527-0.189,0.783c-0.125,0.183-0.195,0.41-0.175,0.674
				c-0.05,0.182-0.102,0.363-0.158,0.54c-0.266,0.847-0.587,1.653-0.954,2.396c-0.139,0.281-0.336,0.553-0.544,0.841
				c-0.183,0.253-0.373,0.515-0.538,0.804c-0.311,0.545-0.227,0.937-0.101,1.169c0.127,0.233,0.412,0.519,1.05,0.556
				c0.049,0.008,0.188,0.089,0.281,0.143c0.105,0.061,0.224,0.131,0.354,0.189c0.193,0.086,0.385,0.174,0.577,0.261
				c0.547,0.249,1.112,0.507,1.694,0.724c0.4,0.149,0.805,0.237,1.196,0.321c0.157,0.034,0.305,0.066,0.452,0.102
				c0.082,0.028,0.166,0.043,0.251,0.046c0.457,0.017,0.726-0.3,0.89-0.502c0.004,0.005,0.009,0.01,0.014,0.015
				c0.186,0.205,0.411,0.334,0.593,0.437c0.066,0.037,0.128,0.073,0.18,0.107l0.648,0.425l0.299-0.715
				c0.029-0.069,0.065-0.144,0.103-0.223c0.101-0.209,0.216-0.445,0.268-0.723c0.094-0.505,0.173-1.018,0.248-1.513
				c0.145-0.948,0.281-1.844,0.524-2.718c0.165-0.592,0.43-1.164,0.71-1.769c0.252-0.544,0.512-1.106,0.703-1.7
				c0.361-1.124,0.66-2.279,0.948-3.395c0.154-0.597,0.314-1.214,0.48-1.815c0.294-1.063,0.439-1.174,1.548-1.18
				c0.592-0.003,1.191-0.017,1.77-0.031c0.92-0.022,1.871-0.045,2.797-0.022c1.004,0.025,2.093,0.06,3.425,0.111
				c0.815,0.031,1.635,0.065,2.467,0.099c0.566,0.023,1.137,0.047,1.714,0.07c-0.035,0.095-0.067,0.185-0.097,0.276
				c-0.119,0.352-0.239,0.704-0.359,1.055c-0.351,1.03-0.713,2.095-1.054,3.15l-0.123,0.381c-0.501,1.552-1.02,3.158-1.476,4.757
				c-0.058,0.203-0.16,0.425-0.267,0.661c-0.353,0.772-0.878,1.92,0.216,3.021c-0.007,0.039-0.017,0.085-0.03,0.133
				c-0.352,1.277-0.006,2.293,0.973,2.915c-0.073,0.231-0.13,0.485-0.086,0.762l0.008,0.051c0.095,0.595,0.214,1.336,0.645,1.872
				c0.294,0.366,0.836,0.439,1.132,0.451c0.249,0.01,0.715-0.012,1.039-0.263c0.533-0.413,0.986-1.068,1.244-1.799
				c0.325-0.918,0.612-1.86,0.891-2.77c0.236-0.771,0.48-1.568,0.742-2.339c0.332-0.975,0.717-1.958,1.09-2.909
				c0.158-0.402,0.315-0.804,0.469-1.208c0.185-0.482,0.368-0.964,0.552-1.447c0.486-1.277,0.99-2.598,1.499-3.888
				c0.366-0.926,0.748-1.865,1.118-2.774c0.544-1.338,1.107-2.721,1.632-4.096c0.488-1.28,0.963-2.587,1.422-3.851
				c0.617-1.698,1.254-3.455,1.93-5.158c0.695-1.751,1.496-3.503,2.27-5.198c0.242-0.529,0.483-1.058,0.723-1.589
				C258.905,80.908,258.924,80.468,258.732,80.108z"
    />
    <path
      d="M327.719,111.399c-0.367-0.523-0.658-0.936-0.712-1.624c-0.077-0.984-0.985-1.748-1.863-2.487
				c-0.208-0.175-0.405-0.341-0.58-0.501c-0.522-0.479-1.078-0.921-1.617-1.349c-0.727-0.578-1.414-1.124-2.024-1.752
				c-1.463-1.508-2.844-3.161-4.179-4.759l-0.376-0.45c-0.863-1.032-1.674-2.152-2.458-3.235l-0.026-0.036
				c-0.001-0.001-0.002-0.003-0.003-0.004c0.02-0.003,0.042-0.005,0.065-0.008c2.255-0.245,4.042-0.654,5.622-1.286
				c1.934-0.774,3.52-1.875,4.848-3.366c1.417-1.591,2.225-2.972,2.622-4.48c0.76-2.89-0.822-5.083-2.217-7.018
				c-0.249-0.344-0.649-0.425-0.942-0.483c-0.089-0.018-0.221-0.044-0.27-0.067c-1.462-1.243-3.278-2.083-5.551-2.569
				c-0.318-0.068-0.633-0.156-0.966-0.248c-0.426-0.119-0.867-0.242-1.324-0.322c-0.342-0.06-0.695-0.131-1.036-0.199
				c-1.04-0.208-2.116-0.423-3.202-0.423c-0.053,0-0.106,0-0.159,0.002c-2.811,0.055-5.878,0.243-9.376,0.574
				c-1.469,0.139-3.153,0.385-4.54,1.545c-0.641,0.537-0.954,1.104-0.929,1.684c0.015,0.347,0.166,0.854,0.799,1.298
				c0.226,0.158,0.506,0.235,0.856,0.235c0.077,0,0.154-0.004,0.23-0.009l-0.009,0.18c-0.107,0.079-0.237,0.16-0.373,0.246
				c-0.474,0.297-1.01,0.634-1.137,1.213c-0.093,0.427-0.041,0.781,0.155,1.05c0.293,0.404,0.768,0.452,1.114,0.486
				c0.034,0.003,0.075,0.007,0.119,0.013c-0.081,0.343-0.162,0.688-0.243,1.034c-0.274,1.167-0.557,2.374-0.856,3.562
				c-0.686,2.727-1.502,5.948-2.35,9.16c-0.365,1.383-0.75,2.788-1.122,4.146c-0.453,1.656-0.922,3.369-1.361,5.061
				c-0.289,1.112-0.56,2.304-0.595,3.488c-0.022,0.771,0.899,1.562,1.543,1.768c0.147,0.047,0.295,0.071,0.438,0.071
				c0.209,0,0.434-0.054,0.641-0.179c-0.086,0.639,0.048,1.236,0.38,1.688c0.329,0.448,0.826,0.733,1.439,0.825
				c0.118,0.018,0.234,0.027,0.346,0.027c0.952,0,1.643-0.642,1.85-1.718c0.026-0.133,0.032-0.261,0.037-0.375
				c0.002-0.044,0.005-0.102,0.009-0.146c1.8-1.898,2.317-4.274,2.817-6.574c0.055-0.255,0.111-0.51,0.168-0.763
				c0.385-1.712,0.603-3.475,0.813-5.179c0.094-0.763,0.192-1.551,0.302-2.32c0.045-0.31,0.377-0.541,0.644-0.541
				c0.083,0,0.15,0.022,0.206,0.067c0.327,0.263,0.609,0.623,0.907,1.005l0.128,0.164c0.544,0.689,1.084,1.408,1.606,2.104
				c1.156,1.54,2.352,3.132,3.705,4.556c0.41,0.431,0.79,0.882,1.193,1.36c1.048,1.243,2.132,2.528,3.723,3.386
				c1.114,0.601,2.12,1.384,3.184,2.213c0.452,0.352,0.92,0.716,1.392,1.062c0.641,0.469,1.225,0.696,1.783,0.696
				c0.518,0,1.218-0.205,1.79-1.108c1.196,0.298,2.159,0.859,2.934,1.706c0.204,0.223,0.515,0.335,0.925,0.335
				c0.348,0,0.692-0.079,0.824-0.113c0.358-0.092,0.567-0.427,0.68-0.692c0.153-0.36,0.16-0.667,0.021-0.913
				C328.036,111.85,327.875,111.621,327.719,111.399z M310.607,89.576c-0.316,0.014-0.632,0.03-0.948,0.045
				c-1.066,0.052-2.167,0.107-3.243,0.107c-0.116,0-0.233-0.001-0.349-0.002c-0.515-0.006-1.038-0.102-1.644-0.213
				c-0.066-0.012-0.132-0.024-0.199-0.036c0.053-0.177,0.105-0.353,0.156-0.529c0.268-0.906,0.52-1.761,0.816-2.61
				c0.26-0.748,0.552-1.506,0.835-2.238c0.287-0.744,0.584-1.514,0.852-2.284c0.064-0.183,0.1-0.246,0.115-0.267
				c0.022-0.008,0.099-0.028,0.29-0.028c3.321,0.001,5.773,0.479,7.95,1.55c0.181,0.089,0.364,0.175,0.548,0.261
				c0.632,0.296,1.228,0.576,1.704,0.966c0.39,0.32,0.6,0.81,0.574,1.344c-0.026,0.545-0.289,1.025-0.722,1.316
				C315.526,88.184,313.303,89.454,310.607,89.576z"
    />
    <path
      d="M364.757,75.091c-0.073-0.16-0.284-0.542-0.65-0.62c-0.512-0.11-1.03-0.152-1.53-0.193l-0.188-0.015
				c-0.127-0.011-0.256-0.016-0.394-0.016c-0.395,0-0.787,0.043-1.166,0.085c-0.367,0.04-0.713,0.079-1.05,0.079
				c-0.693,0-1.178-0.167-1.622-0.557c-0.14-0.123-0.322-0.186-0.541-0.186c-0.156,0-0.315,0.032-0.456,0.059
				c-0.073,0.015-0.142,0.028-0.192,0.034c-0.115,0.013-0.219,0.037-0.31,0.058c-0.086,0.02-0.167,0.039-0.22,0.039
				c-1.336,0.006-2.694,0.008-4.008,0.009c-0.734,0.001-1.469,0.002-2.203,0.003c-0.176,0-0.342,0.021-0.495,0.062
				c-0.635,0.171-1.269,0.344-1.903,0.517c-1.033,0.282-2.094,0.57-3.15,0.845c-0.2-0.337-0.546-0.616-0.857-0.748
				c-0.113-0.048-0.241-0.072-0.382-0.07c-0.343,0.003-0.811,0.166-1.041,0.469c-0.166,0.219-0.261,0.464-0.337,0.693l0.012-0.084
				l-0.249-0.192l-0.147-0.113l-0.147-0.113l-0.292-0.225l-0.316,0.189c-0.116,0.069-0.258,0.135-0.408,0.204
				c-0.398,0.183-0.85,0.391-1.091,0.81c-0.102,0.177-0.204,0.358-0.306,0.544c-0.149,0.036-0.299,0.071-0.448,0.107
				c-0.027,0.007-0.06,0.012-0.094,0.017c-0.215,0.037-0.574,0.098-0.7,0.458c-0.121,0.346-0.346,0.436-0.911,0.586
				c-0.371,0.099-0.791,0.211-1.142,0.512c-0.137,0.118-0.356,0.258-0.568,0.393c-0.548,0.351-1.022,0.654-0.884,1.16
				c0.056,0.205-0.009,0.347-0.15,0.624c-0.115,0.226-0.257,0.507-0.265,0.851c-0.012,0.568,0.771,0.984,1.185,1.012
				c0.085,0.006,0.171,0.009,0.262,0.009c0.322,0,0.638-0.035,1.003-0.076c0.021-0.002,0.041-0.005,0.062-0.007l0.062,1.394
				l0.009,0.199l0.025,0.573l0.092-0.01c-0.062,0.246-0.125,0.492-0.187,0.738c-0.289,1.147-0.587,2.333-0.899,3.492
				c-0.092,0.343-0.009,1.081-0.349,1.315c-0.104,0.071-0.241,0.002-0.354,0.016c-0.148,0.018-0.203,0.096-0.321,0.118
				c-0.519,0.098-1.075-0.006-1.559,0.198c-0.332,0.14-0.627,0.752-0.629,1.13c-0.003,0.547,0.447,0.679,0.799,0.95
				c0.173,0.133,0.523,0.349,0.467,0.621c-0.044,0.212-0.328,0.286-0.391,0.493c-0.069,0.224,0.045,0.498-0.022,0.73
				c-0.051,0.176-0.242,0.387-0.348,0.53c-0.255,0.343-0.554,0.649-0.098,0.976c0.146,0.105,0.383,0.104,0.511,0.242
				c0.272,0.292,0.024,1.152,0.03,1.464c0.006,0.305-0.09,0.531-0.165,0.831c-0.087,0.347-0.125,0.75-0.153,1.107
				c-0.012,0.151-0.069,0.192-0.098,0.3c-0.031,0.12,0.006,0.24-0.021,0.356c-0.049,0.21-0.175,0.449-0.23,0.648
				c-0.063,0.229-0.023,0.42-0.119,0.627c-0.122,0.262-0.273,0.382-0.532,0.478c-0.38,0.14-0.913,0.154-1.261,0.431
				c-0.182,0.144-0.333,0.446-0.222,0.669c0.081,0.164,0.377,0.208,0.298,0.403c-0.061,0.151-0.565,0.278-0.7,0.344
				c-0.271,0.133-0.36,0.226-0.502,0.47c-0.098,0.169-0.205,0.306-0.254,0.497c-0.075,0.293-0.164,0.581,0.065,0.825
				c0.325,0.346,1.092,0.27,1.197,0.806c0.096,0.489-0.049,1.064-0.225,1.478c-0.151,0.355-0.55,0.899-0.29,1.227
				c0.305,0.385,0.82,0.458,1.268,0.515c0.094,0.012,0.275,0.02,0.459,0.041c0.247,0.027,0.5,0.076,0.549,0.185
				c0.092,0.202-0.048,0.591,0.002,0.814c0.016,0.071,0.087,0.181,0.114,0.257c0.066,0.189,0.061,0.309,0.25,0.453
				c0.34,0.26,1.259,0.168,1.636,0.022c0.47-0.182,0.92-0.335,1.405-0.499c0.366-0.124,0.463-0.167,0.569-0.536
				c0.079-0.275,0.171-0.44,0.459-0.506c0.378-0.087,0.82,0.056,1.208,0.007c0.288-0.036,0.465-0.13,0.795-0.128
				c1.064,0.006,2.129,0,3.193,0c0.527,0,1.056,0.014,1.583,0.001c0.335-0.008,0.602-0.131,0.951-0.125
				c0.593,0.009,1.153-0.132,1.749-0.124c0.39,0.005,0.707-0.158,1.036-0.226c0.568-0.117,1.192-0.191,1.771-0.123
				c0.372,0.044,0.723,0.194,1.099,0.209c0.401,0.015,1.046-0.011,1.263-0.411c0.177-0.328,0.159-0.89-0.025-1.204
				c0.851-0.022,1.427-0.418,1.755-1.207c0.023-0.011,0.049-0.024,0.07-0.033c0.143-0.067,0.32-0.15,0.465-0.306
				c0.387-0.417,0.749-0.863,0.565-1.316c-0.192-0.475-0.585-0.926-1.025-1.176c-0.17-0.097-0.371-0.143-0.614-0.14
				c-0.026,0-0.053,0.001-0.079,0.002c-0.017-0.33-0.093-0.634-0.304-0.86c-0.311-0.333-0.758-0.329-0.905-0.327
				c-0.204,0.002-0.448,0.031-0.789,0.094c0.19-0.527,0.074-0.881-0.061-1.088c-0.215-0.33-0.6-0.506-1.145-0.524
				c-0.182-0.006-0.363-0.008-0.538-0.006c-0.363,0.004-0.717,0.024-1.053,0.06c-0.739,0.08-1.488,0.17-2.212,0.257
				c-1.196,0.143-2.433,0.292-3.648,0.399c-0.349,0.031-0.725,0.047-1.185,0.052c-0.329,0.003-0.657,0-1.004-0.003
				c-0.128-0.001-0.258-0.002-0.389-0.003c0.013-0.055,0.026-0.11,0.039-0.164c0.127-0.533,0.238-0.993,0.4-1.427l0.081-0.217
				c0.447-1.197,0.903-2.416,1.366-3.634c0.126-0.012,0.248-0.011,0.376-0.03c0.783-0.101,1.577-0.207,2.346-0.311
				c1.831-0.246,3.725-0.5,5.589-0.693c1.152-0.119,2.334-0.194,3.478-0.267c1.136-0.072,2.311-0.147,3.467-0.265
				c0.595-0.061,1.254-0.192,1.638-0.702c0.326-0.433,0.34-0.991,0.265-1.559c0.468-0.134,0.824-0.411,1.004-0.782
				c0.162-0.335,0.165-0.72,0.009-1.085c-0.064-0.149-0.173-0.281-0.325-0.394c-0.359-0.266-0.906-0.388-1.262-0.389
				c-0.595-0.001-1.178,0.077-1.743,0.152c-0.091,0.012-0.18,0.024-0.267,0.035c-0.247-0.559-0.483-0.982-0.835-1.243
				c-0.513-0.381-1.138-0.335-1.965-0.23c-0.07,0.009-0.131,0.024-0.179,0.035c-0.021,0.005-0.049,0.012-0.059,0.013
				c-0.571,0.04-1.159,0.071-1.727,0.1c-1.272,0.066-2.586,0.135-3.875,0.319c-1.058,0.151-2.113,0.351-3.156,0.558
				c0.119-0.245,0.237-0.493,0.356-0.735c0.026-0.054,0.054-0.11,0.08-0.164c0.09-0.098,0.154-0.218,0.18-0.368
				c0.17-0.347,0.339-0.697,0.503-1.049c0.031-0.067,0.069-0.138,0.106-0.21c0.181-0.346,0.406-0.776,0.275-1.208
				c-0.201-0.661-0.019-1.258,0.194-1.956c2.01-0.169,4.019-0.346,6.018-0.605c1.518-0.197,3.088-0.4,4.627-0.662
				c0.397-0.068,0.807-0.09,1.241-0.114c1.023-0.056,2.081-0.115,3.078-0.773c0.181-0.119,0.409-0.208,0.652-0.302
				c0.328-0.127,0.667-0.259,0.973-0.492c0.356-0.272,0.38-0.853,0.374-1.091c-0.01-0.386-0.119-0.644-0.333-0.791
				c-0.416-0.285-0.872-0.476-1.312-0.661c-0.204-0.086-0.398-0.167-0.586-0.256c-0.028-0.013-0.057-0.028-0.085-0.042
				c0.114-0.103,0.202-0.232,0.23-0.408c0.044-0.275,0.073-0.31,0.463-0.31c0.045,0,0.09,0.001,0.134,0.001
				c0.05,0.001,0.099,0.001,0.148,0.001c0.087,0,0.159-0.002,0.226-0.007c0.113-0.008,0.237-0.011,0.389-0.011
				c0.112,0,0.223,0.002,0.335,0.004c0.117,0.002,0.235,0.004,0.352,0.004c0.315,0,0.689-0.012,1.067-0.102
				c0.518-0.124,0.807-0.663,1.04-1.096c0.021-0.039,0.041-0.077,0.061-0.113C364.881,75.576,364.875,75.348,364.757,75.091z"
    />
    <path
      d="M290.73,84.84c-0.01-0.35-0.315-0.531-0.38-0.857c-0.062-0.308,0.038-0.603,0.188-0.858
				c0.264-0.45,0.306-0.746,0.309-1.245c0.003-0.391-0.002-0.442-0.366-0.612c-0.379-0.177-0.359-0.252-0.365-0.641
				c-0.007-0.442-0.261-0.468-0.498-0.794c-0.207-0.285-0.059-0.377,0.103-0.641c0.117-0.191,0.23-0.474,0.131-0.705
				c-0.126-0.296-0.447-0.245-0.706-0.242c-1.004,0.013-2.01,0.003-3.014,0c-0.702-0.002-1.381,0.116-2.076,0.125
				c-0.493,0.006-0.915-0.021-1.377,0.154c-0.572,0.217-1.209,0.261-1.808,0.366c-1.456,0.255-2.913,0.506-4.37,0.751
				c-0.453,0.076-0.91,0.15-1.367,0.224c-0.228-0.837-0.76-1.371-2.152-0.988c-0.624,0.172-1.025,1.08-1.714,1.017
				c-0.054-0.027-0.115-0.062-0.196-0.107c-0.137-0.076-0.273-0.155-0.409-0.233c-0.394-0.465-0.546-1.203-1.485-0.923
				c-0.443,0.132-0.799,0.457-1.125,0.783c-0.56,0.56-1.05,1.19-1.503,1.853c-0.969,0.181-1.963,0.411-2.885,0.796
				c-0.712,0.297-1.053,1.463-0.975,2.135c0.018,0.154,0.057,0.298,0.116,0.429c0.146,0.142,0.258,0.305,0.337,0.49
				c-0.242,0.263-0.64,0.326-0.911,0.58c-0.172,0.161-0.307,0.35-0.423,0.554c-0.088,0.155-0.161,0.321-0.221,0.489
				c-0.11,0.312-0.174,0.644-0.169,0.976c0.009,0.571,0.23,1.157,0.745,1.453c0.688,0.397,1.629,0.132,2.353-0.01
				c0.174-0.034,0.348-0.076,0.522-0.113c-0.171,1.605-0.872,3.139-1.326,4.682c-0.375,1.274-0.741,2.558-1.104,3.836
				c-0.36,0.065-0.715,0.052-1.097,0.049c-0.443-0.003-0.751,0.129-1.169,0.193c-0.041,0.263-0.125,0.694-0.227,0.925
				c-0.142,0.325-0.544,0.575-0.756,0.863c-0.415,0.564-0.638,1.171-0.867,1.82c-0.081,0.23-0.072,0.57-0.193,0.789
				c-0.154,0.28-0.361,0.191-0.285,0.609c0.104,0.58,0.274,1.023,0.894,1.075c0.396,0.033,0.653,0.213,1.038,0.297
				c0.244,0.053,0.506,0.06,0.77,0.055c-0.189,0.66-0.378,1.321-0.57,1.975c-0.249,0.848-0.551,1.704-0.843,2.533
				c-0.334,0.949-0.673,1.93-0.951,2.925c-0.816,0.161-1.631,0.348-2.463,0.572c-0.214,0.058-0.488,0.103-0.617,0.307
				c-0.121,0.191,0.002,0.326-0.062,0.511c-0.067,0.192-0.348,0.331-0.5,0.474c-0.285,0.269-0.56,0.555-0.833,0.835
				c-0.63,0.645-1.479,2.336-0.174,2.773c0.447,0.15,1.231,0.046,1.17,0.702c-0.037,0.398-0.269,0.695-0.18,1.152
				c0.08,0.413,0.222,0.76,0.666,0.834c0.48,0.08,1.009-0.094,1.49-0.016c0.17,0.028,0.375,0.119,0.554,0.244
				c0.029,0.027,0.057,0.052,0.091,0.078c0.14,0.115,0.261,0.245,0.312,0.384c0.064,0.175-0.046,0.44,0.058,0.591
				c0.087,0.125,0.49,0.287,0.627,0.33c0.427,0.135,1.005,0.033,1.383-0.097c0.319,0.295,0.725,0.468,1.216,0.468
				c0.225,0,0.47-0.033,0.727-0.099c0.586-0.15,1.676-0.43,1.639-1.83c-0.001-0.023,0.009-0.053,0.011-0.078
				c0.212-0.026,0.409-0.09,0.589-0.202l0.467,0.239l0.321-0.459c0.072-0.103,0.157-0.205,0.247-0.314
				c0.164-0.198,0.325-0.429,0.451-0.687c0.518-0.031,0.98,0.384,1.495,0.498c0.519,0.115,1.017-0.031,1.522-0.145
				c0.59-0.133,1.188-0.228,1.788-0.298c1.237-0.144,2.484-0.181,3.728-0.2c1.603-0.025,3.209-0.01,4.807-0.146
				c1.323-0.113,2.643-0.25,3.961-0.405c0.658-0.078,1.317-0.156,1.974-0.245c0.287-0.039,0.406-0.163,0.662-0.254
				c0.203-0.072,0.427-0.067,0.631-0.134c0.347-0.115,0.781-0.235,1.077-0.451c0.707-0.518-0.151-1.214-0.598-1.481
				c-0.243-0.145-0.507-0.16-0.485-0.45c0.001-0.018,0.082-0.163,0.093-0.2c0.065-0.209,0.139-0.397,0.238-0.604
				c0.156-0.324,0.201-0.538,0.166-0.905c-0.04-0.411,0.037-0.888-0.03-1.292c-0.014-0.087-0.006-0.124-0.054-0.204
				c-0.158-0.262-0.5-0.285-0.568-0.612c-0.075-0.362,0.204-0.65,0.323-0.956c0.051-0.132,0.208-0.552,0.175-0.682
				c-0.037-0.146-0.813-0.5-0.971-0.542c-0.533-0.14-1.202-0.028-1.75-0.021c-0.378,0.004-0.736,0.117-1.109,0.183
				c-1.022,0.179-2.051,0.07-3.068,0.211c-0.475,0.066-0.974,0.139-1.442,0.234c-1.748,0.357-3.614,0.38-5.394,0.563
				c-1.483,0.152-3.143,0.232-4.542,0.802c-0.225,0.092-0.437,0.155-0.653,0.202c0.093-0.384,0.188-0.773,0.278-1.15
				c0.265-1.112,0.508-2.246,0.743-3.342c0.183-0.851,0.373-1.719,0.571-2.585c0.707-0.082,1.41-0.045,2.158-0.156
				c0.932-0.12,1.878-0.247,2.793-0.37c2.18-0.293,4.435-0.595,6.654-0.825c0.211-0.022,0.585,0.022,0.731-0.148
				c0.149-0.174,0.075-0.416,0.306-0.581c0.22-0.156,0.392-0.082,0.635-0.115c0.406-0.055,0.642-0.364,0.638-0.74
				c-0.005-0.413,0.003-0.67,0.211-1.011c0.204-0.333,0.25-0.622,0.34-1.004c0.086-0.364-0.064-0.383-0.295-0.672
				c-0.21-0.262-0.395-0.556-0.567-0.844c-0.208-0.349-0.226-0.359-0.01-0.682c0.121-0.181,0.248-0.36,0.355-0.55
				c0.201-0.357,0.405-0.855-0.026-1.102c-0.454-0.261-1.014-0.272-1.523-0.266c-1.216,0.014-2.413,0.088-3.625,0.261
				c-0.927,0.132-1.883,0.251-2.792,0.477c-0.547,0.136-0.946,0.464-1.449,0.683c-0.724,0.315-1.473,0.094-2.226,0.167
				c-0.128,0.012-0.249,0.048-0.371,0.078c0.302-1.06,0.605-2.095,0.91-3.087c0.493-1.6,1.098-3.203,1.689-4.758
				c1.148-0.214,2.291-0.481,3.422-0.65c2.64-0.393,5.294-0.688,7.946-0.983c1.121-0.125,2.382-0.111,3.453-0.494
				c0.41-0.147,0.806-0.347,1.206-0.517C290.079,85.714,290.745,85.368,290.73,84.84z"
    />
    <path
      d="M23.366,126.282c1.371-0.566,2.788-1.152,3.747-2.574c0.044-0.036,0.177-0.101,0.276-0.149
				c0.131-0.064,0.279-0.137,0.441-0.234l0.217-0.129l0.071-0.243c0.021-0.073,0.028-0.138,0.036-0.208
				c0.005-0.044,0.015-0.14,0.031-0.194c2.421-2.2,3.594-5.141,4.502-7.992c0.835-2.626,0.216-5.207-1.744-7.269
				c-0.826-0.869-1.792-1.6-2.796-2.114c-1.031-0.528-2.119-0.948-3.172-1.354c-0.452-0.174-0.92-0.354-1.372-0.539
				c-0.111-0.046-0.224-0.097-0.342-0.151c-0.168-0.076-0.341-0.156-0.527-0.225c-0.063-0.024-0.128-0.052-0.193-0.08
				c-0.239-0.103-0.536-0.231-0.866-0.231c-0.047,0-0.093,0.003-0.138,0.008c-0.183,0.021-0.325,0.03-0.447,0.03
				c-0.449,0-0.571-0.12-0.663-0.371c-0.342-0.941-0.094-1.762,0.333-1.984c0.425-0.221,0.854-0.45,1.269-0.672
				c1.325-0.709,2.695-1.442,4.093-1.959c1.208-0.446,2.601-0.579,3.948-0.708l0.327-0.031c0.756-0.073,1.091-0.424,1.239-0.706
				c0.2-0.382,0.17-0.861-0.082-1.314c-0.269-0.482-0.664-0.84-1.046-1.185c-0.063-0.057-0.125-0.113-0.185-0.168
				c0.153-0.065,0.304-0.13,0.452-0.193c0.735-0.313,1.429-0.609,2.11-0.922c0.486-0.223,0.82-0.608,0.917-1.057
				c0.089-0.408-0.025-0.821-0.319-1.161c-0.324-0.376-0.944-0.638-1.506-0.638c-0.12,0-0.233,0.013-0.339,0.037
				c-0.131,0.031-0.239,0.05-0.32,0.056c-0.008-0.066-0.015-0.171-0.009-0.335c0.025-0.685-0.123-1.184-0.45-1.523
				c-0.183-0.19-0.515-0.417-1.053-0.417c-0.118,0-0.245,0.011-0.375,0.033c-1.349,0.227-2.668,0.521-3.904,0.803
				c-0.77,0.176-1.545,0.371-2.295,0.559c-0.554,0.139-1.127,0.283-1.691,0.418c-0.196,0.047-0.403,0.08-0.623,0.114
				c-0.404,0.064-0.821,0.13-1.233,0.292c-0.752,0.297-1.551,0.696-2.441,1.218c-0.955,0.56-2.037,1.195-3.013,1.944
				c-0.453,0.348-0.841,0.753-1.217,1.144c-0.3,0.312-0.583,0.608-0.889,0.867c-0.442,0.374-0.922,0.736-1.386,1.086
				c-0.82,0.618-1.668,1.257-2.396,2.024c-0.682,0.719-1.151,1.601-1.604,2.454c-0.166,0.312-0.322,0.607-0.49,0.896
				c-0.167,0.289-0.152,0.597-0.14,0.823c0.002,0.044,0.005,0.088,0.005,0.13l0,0.102c-0.006,2.009,0.121,3.005,3.051,3.667
				c0.472,0.107,1.725,0.39,2.183,0.957c1.077,1.334,2.263,2.051,3.964,2.397c0.914,0.186,1.792,0.691,2.722,1.227
				c0.29,0.167,0.589,0.339,0.888,0.5c0.96,0.517,1.565,1.442,1.902,2.912c0.037,0.161,0.001,0.419-0.075,0.542
				c-0.555,0.895-1.164,1.841-1.855,2.698c-0.941,1.166-2.214,1.914-3.561,2.706c-0.386,0.227-0.785,0.461-1.173,0.705
				c-0.684,0.43-1.374,0.872-2.042,1.3c-0.778,0.499-1.583,1.014-2.378,1.51c-0.345,0.215-0.802,0.483-1.268,0.642
				c-0.962,0.328-1.854,0.661-2.689,1.002c-0.331,0.061-0.653,0.145-0.952,0.312c-0.155,0.087-0.291,0.183-0.421,0.281
				c-1.225,0.561-2.319,1.152-3.326,1.794c-0.362,0.231-0.497,0.623-0.606,0.939c-0.034,0.1-0.067,0.195-0.1,0.263L0,129.433
				l0.866,0.125c0.072,0.01,0.16,0.032,0.253,0.054c0.187,0.045,0.399,0.096,0.627,0.096c0.122,0,0.239-0.015,0.349-0.045
				c0.279-0.076,0.502-0.114,0.662-0.114c0.124,0,0.162,0.023,0.163,0.023c0,0,0.061,0.07,0.081,0.355
				c-0.032,0.059-0.114,0.165-0.17,0.238c-0.115,0.15-0.246,0.32-0.34,0.52c-0.031,0.066-0.066,0.135-0.101,0.204
				c-0.199,0.396-0.447,0.889-0.349,1.405c0.094,0.499,0.566,0.708,0.945,0.875c0.118,0.052,0.241,0.106,0.316,0.153
				c0.101,0.063,0.205,0.117,0.306,0.169c0.079,0.041,0.201,0.104,0.266,0.149c0.156,0.499,0.54,1.561,1.598,1.561
				c0.333,0,0.69-0.113,1.092-0.347c0.473-0.275,0.996-0.452,1.549-0.639c0.693-0.235,1.409-0.477,2.088-0.933
				c0.73-0.49,1.551-0.849,2.421-1.229c0.559-0.244,1.137-0.497,1.692-0.789c0.991-0.521,1.972-1.088,2.92-1.636
				c0.542-0.314,1.103-0.638,1.657-0.948c0.4-0.224,0.801-0.459,1.188-0.687c0.988-0.58,2.009-1.179,3.054-1.613L23.366,126.282z"
    />
    <path
      d="M132.643,125.328c0.434,0.469,1.082,0.7,1.981,0.707c0.738,0.006,1.49,0.023,2.218,0.039
				c1.097,0.025,2.231,0.05,3.351,0.039c0.177-0.002,0.355-0.004,0.532-0.008c1.688-0.036,3.4-0.11,5.056-0.183
				c1.191-0.052,2.423-0.106,3.633-0.145c0.473-0.015,0.979-0.025,1.642-0.031c0.28-0.003,0.56-0.005,0.84-0.007
				c0.281-0.002,0.562-0.004,0.843-0.007c0.569-0.006,1.318-0.016,2.068-0.046c0.923-0.037,1.827-0.208,2.748-0.396
				c0.112-0.023,0.387-0.114,0.508-0.465c0.047-0.135,0.199-0.858-0.074-1.326c1.013-0.026,1.699-0.497,2.09-1.437
				c0.027-0.013,0.058-0.028,0.083-0.04c0.17-0.08,0.382-0.179,0.554-0.364c0.46-0.497,0.891-1.027,0.672-1.567
				c-0.229-0.565-0.697-1.102-1.22-1.4c-0.202-0.115-0.441-0.17-0.731-0.167c-0.032,0-0.063,0.001-0.094,0.003
				c-0.02-0.393-0.11-0.755-0.362-1.024c-0.371-0.397-0.902-0.391-1.077-0.389c-0.243,0.002-0.533,0.037-0.939,0.112
				c0.227-0.627,0.089-1.049-0.072-1.296c-0.256-0.393-0.715-0.603-1.363-0.624c-0.216-0.007-0.432-0.01-0.641-0.007
				c-0.432,0.004-0.854,0.029-1.254,0.072c-0.88,0.095-1.771,0.202-2.634,0.306c-1.424,0.171-2.896,0.347-4.343,0.475
				c-0.415,0.037-0.864,0.056-1.411,0.062c-0.392,0.004-0.782,0-1.195-0.003c-0.153-0.001-0.307-0.003-0.463-0.004
				c0.016-0.066,0.031-0.131,0.047-0.195c0.152-0.634,0.283-1.182,0.476-1.699l0.096-0.258c1.19-3.191,2.421-6.491,3.724-9.693
				c0.485-1.192,1.068-2.38,1.633-3.529c0.032-0.064,0.064-0.131,0.096-0.196c0.107-0.117,0.184-0.259,0.212-0.428
				c0.001-0.003,0.001-0.006,0.002-0.01c0.202-0.414,0.404-0.83,0.599-1.249c0.037-0.08,0.082-0.164,0.126-0.25
				c0.216-0.411,0.484-0.924,0.327-1.438c-0.239-0.786-0.022-1.497,0.229-2.321c0.056-0.184,0.114-0.375,0.168-0.57
				c0.562-2.033,1.031-4.12,1.484-6.139c0.119-0.53,0.238-1.061,0.359-1.591c0.067-0.294,0.018-0.57-0.026-0.813
				c-0.018-0.1-0.035-0.195-0.045-0.293l-0.088-0.834l-0.783,0.3c-0.18,0.069-0.354,0.126-0.522,0.181
				c-0.375,0.123-0.73,0.24-1.056,0.459c-0.095,0.064-0.263,0.177-0.411,0.267c-0.042-0.156-0.083-0.337-0.106-0.441
				c-0.131-0.581-0.699-1.099-1.192-1.309c-0.134-0.057-0.287-0.086-0.455-0.084c-0.408,0.004-0.965,0.197-1.239,0.558
				c-0.198,0.26-0.311,0.552-0.401,0.825l0.014-0.099l-0.297-0.229l-0.175-0.135l-0.175-0.135l-0.347-0.268l-0.376,0.225
				c-0.138,0.082-0.307,0.16-0.486,0.243c-0.474,0.218-1.012,0.466-1.299,0.965c-0.986,1.714-2.035,3.793-2.336,6.208
				c-0.063,0.51-0.218,1.02-0.381,1.561c-0.085,0.28-0.172,0.569-0.247,0.862c-0.187,0.728-0.37,1.457-0.554,2.185
				c-0.344,1.365-0.7,2.777-1.071,4.157c-0.241,0.896-0.511,1.804-0.773,2.682c-0.205,0.688-0.417,1.399-0.615,2.105
				c-0.128,0.456-0.277,0.924-0.421,1.377c-0.394,1.236-0.801,2.514-0.883,3.832c-0.006,0.102-0.012,0.204-0.018,0.305
				c-0.059,1.007-0.115,1.959-0.553,2.741l-0.229,0.408c-1.185,2.109-2.41,4.29-3.062,6.733c-0.185,0.692-0.394,1.476-0.449,2.277
				C132.053,124.257,132.233,124.886,132.643,125.328z"
    />
    <path
      d="M40.898,125.011c0.259,0.106,0.527,0.201,0.799,0.289c1.568,0.506,3.305,0.787,4.589,1.78
				c1.673,1.294,3.082,1.6,5.237,1.69c4.92,0.205,9.841,0.409,14.761,0.614c0.662,0.028,1.407,0.025,1.877-0.443
				c0.47-0.467,0.218-1.527-0.44-1.451c0.89-0.307,1.958-0.861,1.901-1.801c-0.059-0.978-1.263-1.379-2.229-1.534
				c0.649-0.018,0.834-1.021,0.392-1.497c-0.442-0.476-1.157-0.547-1.805-0.596c-5.201-0.393-10.713-1.011-15.453-3.238
				c-0.303-0.142-0.616-0.289-0.927-0.424c-1-0.434-1.68-1.306-2.139-2.743c-0.05-0.157-0.036-0.418,0.029-0.547
				c0.479-0.938,1.006-1.932,1.623-2.844c0.84-1.241,2.046-2.093,3.322-2.995c0.365-0.258,0.743-0.525,1.11-0.8
				c0.646-0.485,1.297-0.984,1.926-1.466c0.734-0.562,1.492-1.143,2.244-1.704c0.326-0.243,0.758-0.548,1.21-0.746
				c0.931-0.408,1.792-0.814,2.595-1.224c0.324-0.088,0.639-0.199,0.922-0.391c0.147-0.1,0.275-0.207,0.396-0.316
				c1.174-0.662,2.215-1.342,3.165-2.066c0.342-0.26,0.443-0.663,0.525-0.986c0.026-0.103,0.051-0.2,0.077-0.27l0.31-0.819
				l-0.874-0.052c-0.073-0.004-0.162-0.018-0.257-0.033c-0.19-0.029-0.405-0.062-0.633-0.043c-0.122,0.01-0.237,0.035-0.344,0.074
				c-0.271,0.099-0.49,0.156-0.65,0.169c-0.123,0.01-0.164-0.009-0.164-0.009c0,0-0.067-0.064-0.11-0.347
				c0.027-0.062,0.1-0.174,0.149-0.251c0.102-0.159,0.218-0.339,0.296-0.547c0.026-0.069,0.054-0.14,0.083-0.212
				c0.165-0.411,0.371-0.923,0.231-1.429c-0.135-0.489-0.623-0.658-1.015-0.793c-0.122-0.042-0.249-0.086-0.327-0.126
				c-0.105-0.054-0.214-0.099-0.319-0.143c-0.082-0.034-0.209-0.087-0.278-0.126c-0.197-0.484-0.669-1.511-1.723-1.422
				c-0.332,0.028-0.678,0.171-1.059,0.437c-0.448,0.313-0.954,0.534-1.49,0.767c-0.67,0.292-1.364,0.594-2.002,1.105
				c-0.687,0.55-1.475,0.976-2.309,1.427c-0.536,0.29-1.091,0.59-1.62,0.928c-0.944,0.602-1.874,1.249-2.773,1.875
				c-0.514,0.358-1.046,0.728-1.572,1.084c-0.38,0.257-0.76,0.525-1.126,0.784c-0.936,0.66-1.903,1.343-2.908,1.863l-0.224,0.116
				c-1.318,0.679-2.682,1.381-3.518,2.879c-0.041,0.039-0.168,0.115-0.262,0.172c-0.125,0.075-0.266,0.16-0.42,0.27l-0.206,0.147
				l-0.051,0.248c-0.015,0.074-0.017,0.14-0.018,0.21c-0.001,0.045-0.003,0.141-0.014,0.196c-2.228,2.395-3.152,5.424-3.817,8.341
				c-0.613,2.686,0.22,5.207,2.346,7.098C38.831,123.935,39.855,124.582,40.898,125.011z"
    />
    <path
      d="M164.863,118.624c0.059,0.63,0.666,1.678,1.445,1.819c0.832,0.151,1.691,0.164,2.431,0.164c0.12,0,0.239,0,0.359-0.001
				c0.118,0,0.236-0.001,0.354-0.001c0.115,0,0.231,0,0.346,0.001l0.028,0c0.251,0,0.485-0.031,0.712-0.061
				c0.193-0.025,0.375-0.049,0.539-0.049c0.048,0,0.093,0.002,0.134,0.006c0.753,0.076,1.52,0.176,2.263,0.272
				c0.778,0.101,1.583,0.206,2.381,0.285c1.027,0.101,2.069,0.177,3.077,0.251c0.738,0.054,1.501,0.11,2.248,0.175
				c0.226,0.02,0.407,0.029,0.573,0.029c1.281,0,1.772-0.646,1.932-1.332l0.922-0.239l2.688-0.697l0.305-0.079l0.125-0.289
				c0.083-0.192,0.176-0.4,0.274-0.618c0.266-0.591,0.568-1.262,0.815-1.966c0.142-0.403,0.356-1.013,0.076-1.571
				c-0.113-0.225-0.357-0.528-0.872-0.688l-0.305-0.095c-0.937-0.294-1.907-0.597-2.912-0.72c-1.599-0.194-3.226-0.344-4.799-0.489
				c-1.066-0.098-2.169-0.2-3.25-0.314c-0.476-0.05-0.964-0.159-1.48-0.274c-0.036-0.008-0.072-0.016-0.108-0.024
				c0.126-0.417,0.244-0.812,0.313-1.233c0.423-2.584,0.825-5.214,1.214-7.757l0.005-0.032c0.154-1.008,0.458-1.977,0.78-3.002
				c0.487-1.551,0.991-3.155,0.94-4.919c-0.017-0.577,0.127-1.184,0.28-1.826c0.083-0.351,0.169-0.714,0.231-1.081l0.06-0.363
				c0.273-1.655,0.531-3.218,1.215-4.694c0.208-0.449,0.204-0.961-0.012-1.405c-0.225-0.465-0.646-0.801-1.153-0.922
				c-0.495-0.118-0.676-0.32-0.878-0.98c-0.17-0.556-0.825-0.986-1.335-1.064c-0.039-0.006-0.079-0.009-0.119-0.009
				c-0.402,0-0.801,0.311-1.185,0.925c-0.041,0.066-0.08,0.132-0.117,0.199c-0.258-0.156-0.512-0.277-0.789-0.277
				c-0.134,0-0.26,0.029-0.376,0.086c-0.484,0.238-0.827,0.634-1.16,1.016c-0.031,0.036-0.062,0.072-0.093,0.107
				c-0.233-0.083-0.491-0.166-0.756-0.166c-0.191,0-0.364,0.044-0.515,0.13c-0.531,0.303-0.676,0.984-0.792,1.532l-0.014,0.065
				c-0.13,0.608-0.244,1.225-0.354,1.822c-0.129,0.701-0.262,1.425-0.421,2.125c-0.173,0.76-0.376,1.533-0.573,2.281
				c-0.356,1.353-0.724,2.753-0.939,4.169c-0.096,0.632-0.232,1.281-0.364,1.909c-0.335,1.593-0.681,3.239-0.475,4.974
				c-0.013,0.03-0.033,0.072-0.047,0.103c-0.037,0.078-0.079,0.167-0.114,0.268c-0.101,0.297-0.212,0.601-0.32,0.894
				c-0.25,0.682-0.509,1.387-0.68,2.12c-0.646,2.756-1.088,4.873-1.433,6.863c-0.175,1.006-0.281,2.243,0.311,3.232
				c-0.015,0.02-0.031,0.04-0.047,0.061C165.153,117.653,164.813,118.093,164.863,118.624z"
    />
    <path
      d="M98.097,125.113c0.225,0.297,0.463,0.585,0.738,0.848c0.009,0.008,0.018,0.016,0.026,0.024
				c0.232,0.265,0.463,0.529,0.693,0.793c0.453,0.519,0.904,1.06,1.34,1.584c0.06,0.073,0.123,0.147,0.184,0.22
				c0.065,0.115,0.143,0.204,0.228,0.273c0.241,0.288,0.484,0.576,0.729,0.861c0.255,0.296,0.654,0.319,0.77,0.319
				c0.122,0,0.235-0.02,0.338-0.059c0.112-0.042,0.253-0.085,0.402-0.129c0.568-0.17,1.211-0.363,1.386-0.936
				c0.071-0.233,0.071-0.477,0.024-0.722c0.182,0.087,0.385,0.132,0.606,0.132c0.375,0,0.703-0.136,0.913-0.25
				c1.56-0.85,1.277-1.932,0.969-2.502c-1.213-2.243-2.483-3.901-3.997-5.22c-0.625-0.544-1.283-1.129-1.865-1.761
				c-0.745-0.81-1.488-1.653-2.207-2.467c-0.551-0.625-1.121-1.271-1.691-1.901c-0.443-0.491-0.899-0.982-1.339-1.458
				c-0.69-0.745-1.403-1.515-2.079-2.291c0.004-0.021,0.008-0.044,0.014-0.065c2.644-1.061,5.016-2.465,6.996-3.705
				c3.297-2.065,4.587-5.036,3.729-8.591c-0.209-0.865-0.625-1.995-1.424-2.469c-1.565-0.928-3.43-1.952-5.401-2.514
				c-1.582-0.451-3.264-0.503-4.89-0.553c-0.205-0.006-0.409-0.013-0.612-0.02c-0.143-0.005-0.289-0.008-0.434-0.008
				c-1.034,0-2.106,0.13-3.099,0.376c-1.193,0.296-2.348,0.683-3.465,1.059c-0.309,0.104-0.606,0.204-0.899,0.3
				c-0.024-0.035-0.048-0.069-0.072-0.104c-0.376-0.543-0.602-0.871-1.031-0.871c-0.079,0-0.157,0.014-0.232,0.04
				c-0.527,0.19-1.928,0.694-1.9,1.571c0.01,0.328-0.128,0.473-0.442,0.771c-0.14,0.133-0.299,0.283-0.438,0.467
				c-0.087,0.115-0.218,0.235-0.357,0.363c-0.28,0.257-0.597,0.548-0.749,0.971l-0.218,0.605c-0.715,1.984-1.455,4.035-1.988,6.119
				c-0.383,1.496-0.667,3.031-0.942,4.517c-0.192,1.035-0.39,2.105-0.619,3.144c-0.262,1.191-0.591,2.396-0.91,3.561
				c-0.156,0.571-0.318,1.162-0.47,1.747c-0.118,0.451-0.246,0.911-0.369,1.357c-0.303,1.091-0.617,2.219-0.821,3.362
				c-0.033,0.182-0.063,0.367-0.092,0.554c-0.019,0.071-0.03,0.141-0.032,0.21c-0.258,1.786-0.249,3.742,1.237,5.47l0.161,0.187
				l0.245,0.032c0.15,0.02,0.295,0.029,0.43,0.029c1.017,0,1.487-0.55,1.703-1.011c0.003-0.006,0.006-0.012,0.008-0.018
				c-0.036,0.158-0.072,0.316-0.107,0.472c-0.052,0.232-0.104,0.464-0.159,0.698l-0.134,0.575l0.562,0.183
				c0.276,0.09,0.54,0.135,0.786,0.135c0.824,0,1.451-0.507,1.721-1.392c0.651-2.135,1.25-4.32,1.829-6.433l0.112-0.408l0.157-0.572
				c0.595-2.167,1.21-4.409,1.754-6.633c0.105-0.428,0.228-0.513,0.595-0.581l0.29-0.053c0.828-0.151,1.684-0.308,2.504-0.6
				c0.129-0.046,0.244-0.07,0.341-0.07c0.146,0,0.32,0.046,0.602,0.382c0.424,0.503,0.85,1.022,1.262,1.523
				c0.396,0.482,0.806,0.981,1.215,1.468c0.723,0.86,1.676,1.989,2.645,3.097c1.012,1.157,2.028,2.312,3.044,3.466
				C96.621,123.433,97.359,124.272,98.097,125.113z M89.444,104.161c-0.937,0.252-1.766,0.772-2.568,1.275
				c-0.355,0.223-0.69,0.433-1.033,0.621c-0.085,0.047-0.165,0.097-0.235,0.142c-0.076,0.048-0.19,0.121-0.233,0.131l-0.071,0.013
				c-0.142,0.027-0.301,0.056-0.431,0.065c-0.007-0.095-0.007-0.214,0.006-0.328c0.051-0.445,0.135-0.885,0.224-1.35
				c0.054-0.283,0.11-0.576,0.157-0.869c0.029-0.178,0.057-0.526-0.077-0.864c-0.077-0.195-0.19-0.38-0.29-0.544
				c-0.053-0.087-0.127-0.209-0.165-0.288c0.324-0.916,0.705-1.854,1.269-2.578c0.213-0.273,1.006-0.428,1.479-0.52
				c1.557-0.303,3.094-0.562,4.721-0.836c0.572-0.096,1.147-0.193,1.726-0.292c0.307,0.09,0.617,0.178,0.918,0.262
				c0.806,0.227,1.639,0.463,2.423,0.751c0.076,0.028,0.171,0.18,0.2,0.386c0.032,0.233-0.035,0.401-0.091,0.441
				C94.951,101.538,92.445,103.356,89.444,104.161z"
    />
    <path
      d="M106.261,111.246c0.412-0.091,0.711-0.265,0.934-0.477c-0.062,0.698-0.145,1.37-0.31,2.02
				c-0.39,1.542-0.029,3.335,1.018,5.049c1.101,1.802,2.807,3.236,4.246,3.567c1.792,0.413,3.655,0.535,5.458,0.654
				c0.528,0.035,1.075,0.071,1.607,0.113c0.292,0.023,0.586,0.035,0.871,0.035c3.014,0,5.552-1.266,7.544-3.761
				c1.01-1.265,1.928-2.765,2.73-4.46c1.277-2.701,2.868-6.062,1.759-9.828c-0.344-1.169-0.772-2.624-2.298-3.388l-0.049-0.024
				c-0.12-0.06-0.362-0.181-0.511-0.275c-0.019-1.124-0.613-1.898-1.14-2.588c-0.114-0.149-0.232-0.302-0.34-0.455
				c-0.065-0.092-0.126-0.184-0.185-0.274c-0.326-0.498-0.771-1.179-1.779-1.179c-0.076,0-0.155,0.004-0.235,0.012
				c-0.533,0.052-1.084,0.081-1.618,0.11c-0.743,0.039-1.511,0.08-2.272,0.183c-0.184,0.025-0.369,0.049-0.556,0.072
				c-1.768,0.227-3.771,0.483-5.237,1.8c-0.874,0.786-1.848,1.314-2.879,1.874c-0.297,0.161-0.604,0.328-0.909,0.501
				c-2.319,1.32-4.06,2.933-5.322,4.931c-0.977,1.546-1.405,3.243-1.31,5.189l0.037,0.761L106.261,111.246z M114,106.225
				c0.363-0.262,0.701-0.549,1.027-0.827c0.368-0.313,0.716-0.609,1.088-0.862c0.062-0.042,0.173-0.073,0.306-0.1
				c-0.011,0.158-0.018,0.303-0.014,0.452c0.02,0.834,0.653,1.487,1.44,1.487c0.29,0,0.574-0.093,0.822-0.269
				c1.356-0.963,2.929-1.331,4.594-1.721l0.36-0.085c0.114-0.027,0.225-0.04,0.33-0.04c0.264,0,0.584,0.077,0.608,0.442
				c0.105,1.562,0.069,3.183,0.038,4.613c-0.022,1.016-0.557,1.557-2.045,2.064c-1.414,0.482-2.903,0.727-4.426,0.727
				c-0.972,0-1.987-0.099-3.018-0.295c-0.937-0.178-2.112-0.7-2.39-2.181C112.45,108.179,112.88,107.033,114,106.225z"
    />
    <path
      d="M83.978,169.43l-0.001-0.008l-0.001-0.008l-0.11-0.675c-0.082-0.503-0.164-1.006-0.248-1.509
				c-0.173-1.028-0.66-1.859-1.131-2.663c-0.153-0.261-0.297-0.508-0.433-0.762c-0.408-0.768-1.038-1.65-2.3-1.65
				c-0.02,0-0.041,0-0.061,0.001l-0.031,0c-0.363,0-0.486-0.127-0.857-0.567l-0.093-0.11l-0.151-0.178
				c-0.95-1.121-2.026-2.391-3.833-2.391c-0.24,0-0.488,0.023-0.74,0.069c-0.498-0.473-1.091-0.703-1.808-0.703
				c-0.435,0-0.849,0.082-1.248,0.162c-0.306,0.061-0.596,0.119-0.851,0.128c-0.797,0.03-1.537,0.313-2.253,0.587
				c-0.571,0.218-1.109,0.424-1.639,0.491c-1.832,0.232-3.179,1.2-4.481,2.136c-0.448,0.322-0.87,0.626-1.313,0.902
				c-0.902,0.563-1.55,1.458-1.935,2.11c-0.809,1.369-1.504,2.819-2.177,4.221l-0.093,0.193c-0.09,0.188-0.183,0.376-0.275,0.565
				c-0.443,0.901-0.901,1.834-1.159,2.834c-0.79,3.064-0.886,6.124-0.285,9.095c0.174,0.858,0.436,1.695,0.689,2.505
				c0.142,0.454,0.289,0.923,0.416,1.383c0.534,1.94,3.577,4.705,5.709,4.713c0.507,0.002,1.029,0.017,1.534,0.032
				c0.549,0.016,1.116,0.033,1.678,0.033c1.148,0,2.658-0.063,4.161-0.55c0.39-0.127,0.791-0.249,1.179-0.368
				c0.629-0.192,1.28-0.391,1.916-0.616c2.048-0.727,3.508-2.128,4.919-3.484c2.259-2.169,3.854-4.396,4.876-6.808
				c0.206-0.485,0.431-0.979,0.649-1.458C83.266,174.738,84.372,172.313,83.978,169.43z M72.798,180.031
				c-0.187,0.15-0.373,0.304-0.56,0.459c-0.851,0.703-1.73,1.429-2.673,1.815c-0.229,0.094-0.458,0.19-0.688,0.286
				c-1.455,0.611-2.96,1.243-4.516,1.243c-0.178,0-0.358-0.008-0.533-0.025c-1.931-0.181-3.188-1.416-3.361-3.304
				c-0.429-4.673,1.18-8.517,4.92-11.751c0.578-0.5,0.953-1.207,1.26-1.83c0.531-1.081,1.44-1.243,1.934-1.243
				c0.711,0,1.451,0.321,1.931,0.838c0.168,0.181,0.168,0.859,0.168,1.308c0,0.177,0,0.36,0.006,0.534
				c0.017,0.448-0.002,0.898-0.022,1.374c-0.021,0.484-0.042,0.985-0.022,1.49c0.03,0.758,0.196,1.534,1.198,2.078
				c0.209,0.113,0.413,0.535,0.611,0.943c0.065,0.135,0.132,0.273,0.203,0.41c-0.15,0.228-0.316,0.481-0.478,0.743
				c-0.079,0.128-0.172,0.301-0.233,0.419c-0.105,0.206-0.385,0.752,0.131,1.073c0.094,0.058,0.122,0.076,0.049,0.545
				c-0.071,0.452-0.189,1.208,0.521,1.71c0.249,0.176,0.49,0.3,0.727,0.374C73.185,179.7,72.996,179.871,72.798,180.031z"
    />
    <path
      d="M34.347,163.465c-0.014,0.128-0.031,0.23-0.06,0.31c-0.17,0.465-0.366,0.939-0.556,1.397
				c-0.342,0.825-0.696,1.679-0.936,2.569c-0.38,1.415-0.665,2.87-0.939,4.277c-0.104,0.532-0.211,1.082-0.322,1.62
				c-0.2,0.97-0.396,1.94-0.593,2.911c-0.266,1.31-0.54,2.666-0.818,3.996c-0.081,0.387-0.178,0.784-0.273,1.167
				c-0.152,0.62-0.31,1.26-0.412,1.913c-0.083,0.528-0.061,1.046-0.041,1.503c0.007,0.173,0.014,0.337,0.014,0.481v0.251l0.17,0.185
				c0.155,0.169,0.316,0.32,0.471,0.466c0.28,0.263,0.544,0.512,0.649,0.755c0.049,0.113,0.062,0.32,0.074,0.521
				c0.033,0.536,0.1,1.609,1.368,1.765c0.29,0.347,0.706,0.544,1.148,0.544c0.306,0,0.594-0.094,0.832-0.262
				c0.302,0.816,1.282,1.448,1.978,1.448c0.224,0,0.433-0.06,0.604-0.173c0.842-0.557,1.299-2.353,1.013-3.237
				c-0.14-0.434-0.278-0.932-0.237-1.334c0.316-3.061,0.665-6.077,1.038-8.965c0.402-3.11,0.864-6.255,1.263-8.921l0.012-0.08
				c0.11-0.739,0.224-1.503,0.508-2.125c0.425-0.932,0.851-2.031,0.586-3.352c0.042-0.056,0.104-0.121,0.142-0.144
				c0.052-0.028,0.246-0.039,0.362-0.046c0.112-0.006,0.228-0.013,0.351-0.03c0.77-0.105,1.555-0.207,2.314-0.305
				c1.807-0.234,3.676-0.476,5.509-0.788c0.473-0.081,0.961-0.107,1.478-0.136c1.218-0.067,2.477-0.137,3.664-0.921
				c0.215-0.142,0.487-0.248,0.776-0.36c0.39-0.152,0.794-0.308,1.158-0.586c0.423-0.323,0.452-1.016,0.445-1.299
				c-0.012-0.459-0.142-0.767-0.396-0.942c-0.495-0.339-1.038-0.567-1.562-0.787c-0.244-0.102-0.474-0.199-0.697-0.305
				c-0.034-0.016-0.067-0.033-0.101-0.05c0.136-0.122,0.24-0.276,0.274-0.485c0.053-0.327,0.087-0.369,0.552-0.369
				c0.053,0,0.107,0.001,0.16,0.002c0.059,0.001,0.118,0.002,0.176,0.002c0.104,0,0.189-0.003,0.269-0.008
				c0.135-0.009,0.282-0.013,0.463-0.013c0.133,0,0.266,0.002,0.399,0.004c0.14,0.002,0.279,0.005,0.419,0.005
				c0.375,0,0.82-0.014,1.27-0.122c0.616-0.147,0.961-0.789,1.238-1.305c0.025-0.046,0.049-0.092,0.073-0.135
				c0.125-0.229,0.117-0.5-0.023-0.806c-0.087-0.191-0.338-0.645-0.774-0.738c-0.61-0.131-1.226-0.181-1.822-0.229l-0.224-0.018
				c-0.151-0.013-0.304-0.019-0.47-0.019c-0.471,0-0.937,0.051-1.388,0.101c-0.436,0.048-0.849,0.094-1.25,0.094
				c-0.824,0-1.402-0.198-1.932-0.664c-0.167-0.147-0.383-0.221-0.643-0.221c-0.186,0-0.376,0.038-0.543,0.071
				c-0.087,0.017-0.169,0.034-0.229,0.04c-0.136,0.015-0.26,0.044-0.369,0.07c-0.102,0.024-0.199,0.046-0.261,0.047
				c-1.59,0.007-3.207,0.009-4.771,0.011c-0.874,0.001-1.749,0.002-2.623,0.004c-0.209,0-0.407,0.026-0.589,0.074
				c-0.756,0.203-1.51,0.409-2.265,0.616c-1.436,0.392-2.921,0.798-4.385,1.174c-1.465,0.376-2.965,0.733-4.415,1.078
				c-0.702,0.167-1.404,0.335-2.105,0.505c-0.033,0.008-0.072,0.014-0.112,0.021c-0.256,0.044-0.684,0.117-0.834,0.545
				c-0.145,0.412-0.412,0.519-1.085,0.698c-0.441,0.118-0.942,0.251-1.36,0.609c-0.164,0.14-0.424,0.307-0.676,0.468
				c-0.653,0.418-1.216,0.778-1.053,1.381c0.066,0.244-0.011,0.413-0.179,0.743c-0.136,0.269-0.306,0.603-0.315,1.013
				c-0.014,0.676,0.918,1.171,1.41,1.205c0.102,0.007,0.204,0.01,0.312,0.01c0.384,0,0.759-0.042,1.194-0.091
				c0.024-0.003,0.049-0.005,0.074-0.008l0.074,1.66L26,163.65l0.03,0.683l0.68-0.071l3.957-0.413L34.347,163.465z"
    />
    <path
      d="M216.844,163.561c1.043-1.271,1.816-2.76,2.364-4.552c0.74-2.42,0.513-4.734-0.656-6.692
				c-1.255-2.1-3.453-3.557-6.189-4.103c-0.609-0.121-1.228-0.23-1.826-0.336c-0.411-0.073-0.823-0.145-1.233-0.222
				c-0.382-0.072-0.776-0.16-1.157-0.245c-0.854-0.192-1.736-0.39-2.64-0.438c-0.232-0.012-0.468-0.018-0.722-0.018
				c-0.762,0-1.529,0.057-2.27,0.112c-0.469,0.035-0.953,0.071-1.424,0.091c-0.43,0.019-0.874,0.023-1.303,0.028
				c-0.396,0.004-0.8,0.01-1.205,0.025c0.02-0.429,0.031-0.856-0.04-1.255c-0.097-0.553-0.228-0.832-0.828-0.818
				c-0.437,0.01-0.453,0.056-0.824,0.295c-0.266,0.171-0.546,0.244-0.862,0.211c-0.385-0.04-0.626-0.196-0.878-0.495
				c-0.111-0.131-0.25-0.577-0.439-0.637c-0.259-0.082-0.918,0.266-1.116,0.384c-0.429,0.257-0.948,0.583-1.348,0.885
				c-0.516,0.389-0.827,0.985-0.932,1.617c-0.037,0.223,0.022,0.476-0.056,0.682c-0.019,0.05-0.055,0.1-0.095,0.152
				c-0.153,0.006-0.306,0.016-0.459,0.015c-0.897-0.002-1.792,0.301-2.663,0.491c-0.539,0.118-1.129,0.164-1.078,0.863
				c0.026,0.367,0.243,0.565,0.075,0.909c-0.135,0.277-0.415,0.445-0.53,0.738c-0.259,0.657-0.631,1.728-0.278,2.392
				c0.166,0.313,0.543,0.583,0.663,0.916c0.1,0.277-0.038,0.519-0.133,0.824c-0.126,0.408-0.613,1.207-0.029,1.473
				c0.56,0.255,1.563-0.336,2.126-0.445c0.162-0.032,0.321-0.04,0.481-0.054c-0.008,0.136-0.021,0.271-0.049,0.403
				c-0.071,0.343-0.086,0.612-0.122,0.958c-0.044,0.425-0.174,0.895-0.336,1.285c-0.447,1.077-0.911,2.227-1.23,3.418
				c-0.272,1.014-0.495,2.05-0.712,3.053c-0.157,0.726-0.319,1.478-0.497,2.207c-0.002,0.007-0.004,0.014-0.006,0.021
				c-0.181,0.023-0.362,0.047-0.541,0.106c-0.7,0.233-2.057,0.321-1.861,1.446c0.093,0.532,0.547,0.749,0.506,1.321
				c-0.017,0.241-0.173,0.432-0.185,0.657c-0.013,0.25,0.23,0.632,0.347,0.838c0.122,0.215,0.253,0.449,0.399,0.673
				c-0.569,2.376-1.143,4.798-1.646,7.222c-0.02,0.096-0.041,0.195-0.063,0.295c-0.227,1.061-0.484,2.263-0.025,3.197
				c0.234,0.476,0.27,0.936,0.312,1.468c0.025,0.319,0.051,0.648,0.124,0.993c0.007,0.035,0.008,0.112,0.008,0.18
				c0.001,0.186,0.003,0.442,0.125,0.694c0.131,0.271,0.337,0.458,0.503,0.609c0.055,0.049,0.106,0.096,0.147,0.138l0.454,0.472
				l0.464-0.462c0.04-0.04,0.093-0.085,0.149-0.133c0.17-0.145,0.382-0.326,0.516-0.596c0.16-0.323,0.296-0.655,0.424-0.981
				c0.203,0.114,0.407,0.2,0.635,0.199c0.11,0,0.216-0.021,0.315-0.062c0.064-0.026,0.12-0.047,0.172-0.066
				c-0.003,0.249,0.044,0.509,0.131,0.768c-0.002,0.025-0.003,0.05-0.005,0.075c-0.009,0.119-0.02,0.255-0.01,0.404
				c0.091,1.428,0.938,1.924,1.632,2.089c0.194,0.046,0.379,0.069,0.55,0.069c1.376-0.004,1.548-1.407,1.604-1.868
				c0.03-0.242,0.039-0.477,0.047-0.704c0.01-0.268,0.02-0.52,0.062-0.751l0.141-0.767c0.388-2.114,0.789-4.299,1.239-6.433
				c0.32-1.514,0.967-3.045,0.681-4.591c-0.081-0.438-0.053-0.877,0.007-1.316c0.191-0.039,0.374-0.09,0.575-0.116
				c2.39-0.304,4.762-1.107,7.074-1.761c0.657-0.186,1.314-0.372,1.972-0.553c0.401-0.111,0.815-0.216,1.217-0.317
				c1.172-0.297,2.384-0.603,3.515-1.111c1.936-0.869,3.778-1.98,5.561-3.055l0.077-0.047l0.135-0.081
				C214.942,165.198,216.048,164.532,216.844,163.561z M211.325,157.804c-1.36,1.798-3.284,2.814-5.474,3.865
				c-1.479,0.71-3.031,1.343-4.613,1.884c-1.794,0.612-3.599,1.187-5.489,1.773c0.187-0.752,0.356-1.524,0.546-2.265
				c0.11-0.43,0.292-0.847,0.484-1.263c0.047-0.235,0.086-0.47,0.136-0.703c0.383-1.777,0.776-3.525,1.201-5.272
				c0.026-0.266,0.051-0.532,0.076-0.799c0.123-0.018,0.246-0.04,0.369-0.057c0.434-0.061,0.869-0.121,1.303-0.183
				c0.062-0.009,0.132-0.013,0.221-0.013c0.083,0,0.165,0.003,0.248,0.006c0.099,0.004,0.198,0.007,0.297,0.007h0.66l-0.002-0.097
				c0.254-0.017,0.506-0.036,0.754-0.054c0.973-0.071,1.979-0.144,2.957-0.144l0.136,0l0.074,0c0.229,0,0.458-0.007,0.68-0.014
				c0.222-0.007,0.432-0.014,0.643-0.014c0.826,0,1.505,0.104,2.136,0.61c0.435,0.349,0.987,0.437,1.474,0.514
				c0.295,0.047,0.574,0.091,0.784,0.183c0.374,0.163,0.619,0.432,0.71,0.779C211.74,156.949,211.626,157.405,211.325,157.804z"
    />
    <path
      d="M304.451,180.203c-0.547-0.252-1.071-0.549-1.626-0.862c-0.048-0.027-0.097-0.055-0.145-0.082
				c0.378-0.713,0.478-1.32,0.314-1.878c-0.268-0.913-1.185-1.393-1.852-1.675c-1.08-0.456-2.126-1.111-3.138-1.743
				c-0.312-0.195-0.635-0.397-0.957-0.592c-0.562-0.34-1.126-0.707-1.672-1.061c-0.777-0.505-1.58-1.026-2.407-1.494
				c-0.779-0.44-1.585-0.848-2.365-1.242c-0.905-0.458-1.841-0.931-2.719-1.449c-0.358-0.211-0.676-0.562-1.039-0.97
				c0.129-0.041,0.256-0.081,0.382-0.121c0.863-0.273,1.678-0.531,2.466-0.828c0.443-0.167,0.901-0.33,1.344-0.487
				c2.116-0.752,4.304-1.53,6.09-3.171c0.667-0.613,1.327-1.249,1.965-1.865c0.223-0.215,0.446-0.431,0.67-0.645
				c1.521-1.46,2.305-3.375,2.207-5.395c-0.098-2.018-1.095-3.964-2.668-5.203l-0.289-0.228c-0.974-0.77-1.981-1.566-3.072-2.22
				c-1.079-0.647-2.092-1.028-3.01-1.134c-0.515-0.059-1.073-0.088-1.707-0.088c-0.649,0-1.304,0.03-1.937,0.059
				c-0.266,0.012-0.531,0.024-0.796,0.034c-0.137,0.005-0.274,0.01-0.411,0.015c-1.04,0.036-2.115,0.073-3.162,0.292
				c-0.614,0.128-1.179,0.371-1.726,0.606c-0.417,0.179-0.811,0.348-1.211,0.463c-1.775,0.507-3.607,0.952-5.379,1.382l-0.228,0.055
				c-0.37,0.09-0.745,0.157-1.142,0.228c-0.475,0.085-0.967,0.173-1.453,0.304c-0.766,0.207-0.998,0.62-1.058,0.93
				c-0.047,0.246-0.017,0.594,0.325,0.966c-0.654,0.182-1.276,0.361-1.874,0.611c-0.076,0.032-0.183,0.064-0.296,0.098
				c-0.466,0.14-1.705,0.513-1.339,1.88c0.184,0.687,0.668,1.245,1.094,1.738l0.065,0.075c0.161,0.187,0.391,0.281,0.684,0.281
				c0.109,0,0.216-0.012,0.32-0.026c-0.14,0.385-0.268,0.758-0.358,1.156c-0.105,0.466-0.178,0.937-0.249,1.392
				c-0.084,0.54-0.163,1.049-0.291,1.546c-0.192,0.745-0.371,1.502-0.543,2.235c-0.508,2.154-1.032,4.382-1.953,6.45
				c-0.59,1.325-0.912,2.759-1.224,4.147c-0.124,0.55-0.251,1.119-0.393,1.665c-0.121,0.468-0.228,0.944-0.33,1.403
				c-0.168,0.753-0.327,1.463-0.549,2.162l-0.064,0.199c-0.379,1.178-0.809,2.513,0.345,3.883c0.365,0.433,0.839,0.682,1.301,0.682
				c0.409,0,0.767-0.193,0.984-0.528c0.017-0.027,0.033-0.051,0.049-0.075c0.094,0.226,0.203,0.476,0.36,0.694l0.412,0.57
				l0.531-0.46l0.873-0.756l0.212-0.183c0.221,0.128,0.452,0.193,0.693,0.193c0.733,0,1.275-0.62,1.599-0.99l0.05-0.057
				c0.748-0.853,1.677-1.913,1.119-3.496c-0.001-0.014,0-0.043,0.008-0.077c0.646-2.893,1.23-5.505,1.842-8.168
				c0.016-0.07,0.032-0.124,0.046-0.165c0.42,0.236,0.831,0.485,1.234,0.737c0.11,0.087,0.21,0.164,0.29,0.218
				c0.404,0.273,0.815,0.535,1.232,0.787c0.168,0.124,0.338,0.254,0.512,0.389c0.721,0.555,1.466,1.129,2.352,1.417
				c1.57,0.511,2.852,1.445,4.209,2.434c0.683,0.498,1.39,1.013,2.135,1.467c1.052,0.641,2.095,1.351,3.105,2.038
				c0.525,0.357,1.068,0.726,1.608,1.084c0.152,0.101,0.308,0.176,0.445,0.242c0.135,0.065,0.262,0.127,0.342,0.192
				c1.164,0.947,2.337,1.927,3.471,2.874l0.401,0.335c0.501,0.418,1.077,0.649,1.621,0.649c0.681,0,1.252-0.357,1.565-0.981
				c0.112-0.223,0.196-0.307,0.23-0.334c0.107,0.04,0.332,0.26,0.431,0.358l0.03,0.029c0.573,0.562,1.136,0.812,1.825,0.812
				c0.212,0,0.441-0.024,0.701-0.072c0.617-0.115,1.551-0.29,1.761-1.407C305.853,181.446,305.422,180.65,304.451,180.203z
				 M281.604,160.418c-0.651,0.134-1.323,0.273-1.984,0.42c-0.475,0.105-0.96,0.199-1.516,0.307
				c0.066-0.249,0.131-0.494,0.195-0.736c0.377-1.42,0.734-2.762,1.059-4.1c0.15-0.619,0.18-1.263,0.084-1.815
				c-0.01-0.057-0.016-0.102-0.019-0.137c1.622-0.118,3.142-0.636,4.647-1.582c0.347-0.218,0.934-0.25,1.502-0.281
				c0.227-0.012,0.461-0.025,0.691-0.05c0.345-0.037,0.699-0.055,1.054-0.055c1.963,0,3.845,0.567,5.434,1.13
				c0.031,0.037,0.07,0.1,0.092,0.149c-0.035,0.121-0.068,0.246-0.102,0.373c-0.154,0.574-0.329,1.225-0.634,1.479
				c-0.219,0.182-0.437,0.366-0.656,0.551c-1.539,1.299-3.13,2.642-5.047,3.214C284.84,159.749,283.195,160.089,281.604,160.418z"
    />
    <path
      d="M243.605,175.605c-0.202-0.115-0.441-0.17-0.732-0.167c-0.032,0-0.063,0.001-0.094,0.003
				c-0.02-0.393-0.11-0.755-0.362-1.024c-0.371-0.397-0.902-0.391-1.077-0.39c-0.243,0.003-0.533,0.037-0.939,0.112
				c0.227-0.627,0.089-1.049-0.072-1.296c-0.256-0.393-0.715-0.603-1.363-0.624c-0.216-0.007-0.432-0.01-0.641-0.007
				c-0.432,0.004-0.854,0.029-1.253,0.072c-0.88,0.095-1.771,0.202-2.634,0.306c-1.424,0.171-2.896,0.347-4.344,0.475
				c-0.415,0.037-0.864,0.056-1.411,0.062c-0.392,0.004-0.782,0-1.195-0.003c-0.153-0.001-0.307-0.003-0.463-0.004
				c0.016-0.066,0.031-0.131,0.047-0.195c0.152-0.634,0.283-1.182,0.476-1.699l0.096-0.258c1.19-3.191,2.421-6.491,3.724-9.693
				c0.485-1.192,1.068-2.38,1.633-3.529c0.032-0.064,0.064-0.131,0.096-0.196c0.107-0.117,0.184-0.259,0.212-0.428
				c0.001-0.003,0.001-0.006,0.002-0.01c0.202-0.414,0.404-0.83,0.599-1.249c0.037-0.08,0.082-0.164,0.126-0.25
				c0.216-0.411,0.484-0.924,0.327-1.438c-0.239-0.786-0.022-1.497,0.229-2.321c0.056-0.184,0.114-0.375,0.168-0.57
				c0.562-2.033,1.031-4.12,1.484-6.139c0.119-0.53,0.238-1.061,0.359-1.591c0.067-0.294,0.018-0.57-0.026-0.813
				c-0.018-0.1-0.035-0.195-0.045-0.293l-0.088-0.834l-0.783,0.3c-0.18,0.069-0.354,0.126-0.522,0.181
				c-0.376,0.123-0.73,0.24-1.056,0.459c-0.095,0.064-0.263,0.177-0.411,0.267c-0.042-0.156-0.083-0.337-0.106-0.441
				c-0.131-0.581-0.699-1.099-1.192-1.309c-0.134-0.057-0.287-0.086-0.455-0.084c-0.408,0.004-0.965,0.197-1.239,0.558
				c-0.198,0.26-0.311,0.552-0.401,0.825l0.014-0.099l-0.297-0.229l-0.175-0.135l-0.175-0.135l-0.347-0.268l-0.376,0.225
				c-0.138,0.082-0.307,0.16-0.486,0.243c-0.474,0.218-1.012,0.466-1.299,0.965c-0.986,1.714-2.035,3.793-2.336,6.208
				c-0.063,0.51-0.218,1.02-0.381,1.561c-0.085,0.28-0.172,0.569-0.247,0.862c-0.187,0.728-0.37,1.457-0.554,2.185
				c-0.344,1.365-0.7,2.777-1.071,4.157c-0.241,0.896-0.511,1.804-0.773,2.682c-0.205,0.688-0.417,1.399-0.614,2.105
				c-0.128,0.456-0.277,0.924-0.421,1.377c-0.394,1.236-0.801,2.514-0.883,3.832c-0.006,0.102-0.012,0.204-0.018,0.305
				c-0.059,1.007-0.114,1.959-0.553,2.741l-0.229,0.408c-1.185,2.109-2.41,4.29-3.062,6.733c-0.185,0.692-0.394,1.476-0.449,2.277
				c-0.055,0.799,0.125,1.428,0.534,1.87c0.434,0.469,1.082,0.7,1.981,0.707c0.738,0.006,1.49,0.023,2.218,0.039
				c1.097,0.025,2.231,0.05,3.351,0.039c0.177-0.002,0.355-0.004,0.532-0.008c1.688-0.036,3.4-0.11,5.056-0.183
				c1.191-0.052,2.423-0.106,3.633-0.145c0.473-0.015,0.98-0.025,1.642-0.031c0.28-0.003,0.56-0.005,0.84-0.007
				c0.281-0.002,0.562-0.004,0.843-0.007c0.569-0.006,1.318-0.016,2.068-0.046c0.923-0.037,1.827-0.208,2.748-0.396
				c0.113-0.023,0.387-0.114,0.508-0.465c0.047-0.135,0.199-0.858-0.074-1.326c1.013-0.026,1.699-0.497,2.09-1.437
				c0.027-0.014,0.058-0.028,0.083-0.04c0.17-0.08,0.381-0.179,0.554-0.365c0.46-0.497,0.891-1.027,0.672-1.567
				C244.596,176.44,244.128,175.903,243.605,175.605z"
    />
    <path
      d="M267.042,163.201c0.248-0.5,0.296-1.048,0.382-1.597c0.137-0.88,0.331-1.7,0.111-2.584
				c-0.142-0.57-0.353-1.016-0.632-1.441c-0.197-0.3-0.428-0.589-0.691-0.905c-0.645-0.772-0.977-1.665-1.346-2.585
				c-0.299-0.745-0.079-1.404-0.198-2.176c-0.089-0.581-0.157-1.091-0.167-1.692c-0.012-0.749-0.391-1.535-0.788-2.154
				c-0.259-0.403-0.551-0.614-1.036-0.624c-0.534-0.011-1.03-0.193-1.564-0.169c-0.703,0.031-1.45,0.209-2.157,0.302
				c-0.375,0.049-0.784-0.03-1.15,0.064c-0.466,0.12-0.899,0.286-1.378,0.386c-0.365,0.076-0.709,0.235-1.081,0.252
				c-0.267,0.012-0.53-0.047-0.795,0.019c-0.378,0.093-0.712,0.376-1.05,0.555c-0.397,0.21-0.82,0.319-1.246,0.457
				c-0.322,0.104-0.533,0.243-0.809,0.43c-0.349,0.236-0.675,0.376-0.984,0.674c-0.488,0.47-1.089,0.742-1.596,1.189
				c-1.269,1.119-2.875,1.55-3.876,3.007c-0.346,0.503-0.889,0.722-1.263,1.193c-0.317,0.4-0.506,0.829-0.745,1.273
				c-0.615,1.14-1.99,2.305-1.925,3.711c0.014,0.302,0.123,0.595,0.145,0.899c0.071,0.991-0.093,1.959-0.161,2.942
				c-0.016,0.231-0.035,0.471,0.056,0.692c0.166,0.406,0.56,0.49,0.797,0.796c0.146,0.189,0.097,0.274,0.136,0.485
				c0.055,0.299,0.237,0.564,0.303,0.86c0.163,0.738-0.072,1.574,0.359,2.255c0.283,0.448,0.477,0.831,0.706,1.316
				c0.259,0.55,0.43,1.187,0.798,1.712c0.401,0.574,0.697,1.164,1.193,1.682c1.307,1.366,3.683,1.387,5.399,1.991
				c0.459,0.162,0.991,0.121,1.478,0.215c0.301,0.058,0.701,0.233,1.008,0.227c0.316-0.006,0.64-0.168,0.976-0.182
				c0.501-0.02,1.187-0.055,1.651-0.254c0.6-0.257,1.171-0.792,1.739-1.129c0.619-0.367,1.101-0.706,1.653-1.161
				c0.256-0.211,0.526-0.355,0.755-0.601c0.473-0.508,0.741-1.184,1.15-1.743c0.552-0.756,0.97-1.509,1.385-2.356
				c0.145-0.296,0.261-0.601,0.496-0.828c0.323-0.313,0.688-0.586,1.003-0.916c0.46-0.482,0.794-1.047,1.169-1.592
				c0.379-0.55,0.811-1.07,1.14-1.653C266.617,164.039,266.836,163.615,267.042,163.201z M260.614,159.759
				c-0.076,0.375-0.024,0.736-0.072,1.104c-0.052,0.401-0.291,0.746-0.428,1.123c-0.072,0.197-0.124,0.388-0.175,0.573
				c-0.107,0.39-0.209,0.756-0.479,1.114c-0.225,0.298-0.48,0.559-0.685,0.874c-0.421,0.648-0.719,1.458-1.215,2.047
				c-0.262,0.311-0.584,0.496-0.894,0.748c-0.321,0.26-0.567,0.601-0.872,0.879c-0.417,0.38-0.903,0.461-1.325,0.8
				c-0.409,0.329-0.771,0.741-1.266,0.946c-0.521,0.216-1.123,0.246-1.677,0.357c-0.218,0.044-0.504,0.137-0.734,0.088
				c-0.255-0.054-0.512-0.235-0.753-0.338c-0.406-0.174-0.471-0.417-0.725-0.773c-0.345-0.482-0.864-0.99-1.08-1.555
				c-0.145-0.38-0.09-0.807-0.209-1.202c-0.064-0.214-0.128-0.488-0.223-0.686c-0.118-0.245-0.379-0.434-0.459-0.707
				c-0.157-0.536-0.091-1.107-0.177-1.647c-0.074-0.468-0.425-0.882-0.516-1.362c-0.139-0.736,0.76-1.744,1.189-2.229
				c0.607-0.686,1.274-1.353,2.092-1.783c0.769-0.404,1.664-0.632,2.465-0.982c0.441-0.193,0.906,0.006,1.379-0.116
				c0.219-0.056,0.395-0.201,0.654-0.258c0.353-0.077,0.677-0.083,1.038-0.081c0.644,0.004,1.555-0.169,2.038,0.351
				c0.365,0.393,0.408,1.161,0.947,1.308c0.527,0.144,1.175-0.121,1.712-0.019C260.733,158.441,260.706,159.304,260.614,159.759z"
    />
    <path
      d="M148.794,151.316c-0.012-0.459-0.142-0.767-0.396-0.942c-0.495-0.339-1.038-0.567-1.562-0.787
				c-0.244-0.102-0.474-0.199-0.697-0.305c-0.034-0.016-0.067-0.033-0.101-0.05c0.136-0.122,0.24-0.276,0.274-0.485
				c0.053-0.327,0.087-0.369,0.552-0.369c0.053,0,0.107,0.001,0.16,0.002c0.059,0.001,0.118,0.002,0.176,0.002
				c0.104,0,0.189-0.003,0.269-0.008c0.135-0.009,0.282-0.013,0.463-0.013c0.133,0,0.266,0.002,0.399,0.004
				c0.14,0.002,0.279,0.005,0.419,0.005c0.376,0,0.82-0.014,1.27-0.122c0.617-0.147,0.961-0.789,1.238-1.305
				c0.025-0.046,0.049-0.092,0.073-0.135c0.125-0.229,0.117-0.5-0.023-0.806c-0.087-0.191-0.338-0.645-0.774-0.738
				c-0.61-0.131-1.226-0.181-1.822-0.229l-0.224-0.018c-0.151-0.013-0.304-0.019-0.47-0.019c-0.471,0-0.937,0.051-1.388,0.101
				c-0.436,0.048-0.849,0.094-1.25,0.094c-0.825,0-1.402-0.198-1.932-0.664c-0.167-0.147-0.383-0.221-0.643-0.221
				c-0.186,0-0.376,0.038-0.543,0.071c-0.087,0.017-0.169,0.034-0.229,0.04c-0.136,0.015-0.26,0.044-0.369,0.07
				c-0.102,0.024-0.199,0.046-0.261,0.047c-1.59,0.007-3.207,0.009-4.772,0.011c-0.874,0.001-1.749,0.002-2.623,0.004
				c-0.209,0-0.407,0.026-0.589,0.074c-0.756,0.203-1.51,0.409-2.265,0.616c-1.229,0.336-2.493,0.679-3.751,1.007
				c-0.238-0.401-0.65-0.733-1.021-0.891c-0.134-0.057-0.287-0.086-0.455-0.084c-0.408,0.004-0.965,0.197-1.239,0.558
				c-0.198,0.26-0.311,0.552-0.401,0.825l0.014-0.099l-0.297-0.229l-0.175-0.135l-0.175-0.135l-0.347-0.268l-0.376,0.225
				c-0.138,0.082-0.307,0.16-0.486,0.243c-0.474,0.218-1.012,0.466-1.299,0.965c-0.121,0.21-0.243,0.426-0.364,0.647
				c-0.178,0.043-0.356,0.085-0.533,0.128c-0.033,0.008-0.072,0.014-0.112,0.021c-0.256,0.044-0.683,0.117-0.834,0.545
				c-0.144,0.412-0.412,0.519-1.085,0.698c-0.441,0.118-0.942,0.251-1.36,0.609c-0.164,0.14-0.424,0.307-0.676,0.468
				c-0.653,0.418-1.216,0.778-1.053,1.381c0.066,0.244-0.011,0.413-0.179,0.743c-0.136,0.269-0.306,0.603-0.315,1.013
				c-0.014,0.676,0.918,1.171,1.41,1.205c0.102,0.007,0.204,0.01,0.312,0.01c0.384,0,0.759-0.042,1.194-0.091
				c0.025-0.003,0.049-0.005,0.074-0.008l0.074,1.66l0.01,0.237l0.03,0.683l0.11-0.011c-0.074,0.293-0.149,0.586-0.223,0.879
				c-0.344,1.365-0.7,2.777-1.071,4.157c-0.241,0.896-0.511,1.804-0.773,2.682c-0.205,0.688-0.417,1.399-0.614,2.105
				c-0.128,0.456-0.277,0.924-0.421,1.377c-0.394,1.236-0.801,2.515-0.883,3.832c-0.006,0.102-0.012,0.204-0.018,0.305
				c-0.059,1.007-0.114,1.959-0.553,2.741l-0.229,0.408c-1.185,2.109-2.41,4.29-3.062,6.733c-0.185,0.692-0.394,1.476-0.449,2.277
				c-0.055,0.799,0.125,1.428,0.535,1.87c0.434,0.469,1.082,0.7,1.981,0.707c0.738,0.006,1.49,0.023,2.218,0.039
				c1.097,0.025,2.231,0.05,3.351,0.039c0.177-0.002,0.355-0.005,0.532-0.008c1.688-0.036,3.4-0.111,5.056-0.183
				c1.191-0.052,2.423-0.106,3.633-0.144c0.473-0.015,0.98-0.025,1.642-0.032c0.28-0.003,0.56-0.005,0.84-0.007
				c0.281-0.002,0.562-0.004,0.843-0.007c0.569-0.006,1.318-0.016,2.068-0.046c0.923-0.037,1.827-0.208,2.748-0.396
				c0.112-0.023,0.387-0.114,0.508-0.465c0.047-0.135,0.199-0.858-0.074-1.326c1.013-0.026,1.699-0.497,2.09-1.437
				c0.027-0.013,0.058-0.028,0.083-0.04c0.17-0.08,0.382-0.179,0.554-0.364c0.46-0.497,0.891-1.027,0.672-1.567
				c-0.229-0.565-0.697-1.102-1.22-1.4c-0.202-0.115-0.441-0.17-0.732-0.167c-0.032,0-0.063,0.001-0.094,0.003
				c-0.02-0.393-0.11-0.755-0.362-1.024c-0.371-0.397-0.902-0.391-1.077-0.389c-0.243,0.002-0.533,0.037-0.939,0.112
				c0.227-0.627,0.089-1.049-0.072-1.296c-0.256-0.393-0.715-0.603-1.363-0.624c-0.216-0.007-0.432-0.01-0.641-0.007
				c-0.432,0.004-0.854,0.029-1.254,0.072c-0.88,0.095-1.771,0.202-2.634,0.306c-1.424,0.171-2.896,0.347-4.343,0.475
				c-0.415,0.037-0.864,0.056-1.411,0.062c-0.392,0.004-0.782,0-1.195-0.003c-0.153-0.001-0.307-0.003-0.463-0.004
				c0.016-0.066,0.031-0.131,0.047-0.195c0.152-0.634,0.283-1.182,0.476-1.699l0.096-0.258c0.532-1.426,1.075-2.876,1.626-4.327
				c0.15-0.015,0.295-0.013,0.447-0.036c0.932-0.12,1.878-0.247,2.793-0.37c2.18-0.293,4.435-0.595,6.654-0.825
				c1.372-0.142,2.779-0.231,4.14-0.318c1.353-0.086,2.752-0.175,4.128-0.315c0.708-0.072,1.493-0.228,1.95-0.835
				c0.388-0.515,0.405-1.18,0.315-1.856c0.558-0.159,0.981-0.489,1.195-0.931c0.193-0.399,0.197-0.858,0.011-1.292
				c-0.076-0.177-0.206-0.335-0.387-0.469c-0.427-0.317-1.079-0.462-1.503-0.463c-0.708-0.001-1.403,0.092-2.075,0.181
				c-0.108,0.014-0.214,0.029-0.318,0.042c-0.295-0.666-0.575-1.169-0.994-1.48c-0.611-0.453-1.355-0.399-2.339-0.273
				c-0.084,0.011-0.155,0.028-0.213,0.042c-0.025,0.006-0.058,0.014-0.07,0.016c-0.679,0.048-1.379,0.084-2.056,0.119
				c-1.514,0.079-3.079,0.16-4.614,0.379c-1.26,0.18-2.515,0.418-3.757,0.664c0.142-0.292,0.282-0.588,0.423-0.875
				c0.032-0.064,0.064-0.131,0.096-0.196c0.107-0.117,0.184-0.26,0.212-0.428c0.001-0.003,0.001-0.006,0.002-0.01
				c0.202-0.414,0.404-0.83,0.599-1.249c0.037-0.08,0.082-0.164,0.126-0.25c0.216-0.411,0.484-0.924,0.327-1.438
				c-0.239-0.786-0.022-1.497,0.229-2.321c0.001-0.003,0.002-0.006,0.003-0.008c2.393-0.201,4.785-0.412,7.164-0.721
				c1.807-0.234,3.676-0.476,5.509-0.788c0.473-0.081,0.961-0.107,1.478-0.136c1.218-0.067,2.478-0.137,3.664-0.921
				c0.215-0.142,0.487-0.248,0.776-0.36c0.39-0.151,0.794-0.308,1.158-0.586C148.773,152.292,148.802,151.599,148.794,151.316z"
    />
    <path
      d="M340.063,142.51c0.071-0.005,0.152-0.008,0.216-0.01c0.437-0.016,1.034-0.037,1.402-0.527
				c0.258-0.344,0.311-0.802,0.161-1.401c-0.288-1.149-1.021-1.32-1.432-1.32c-0.353,0-0.681,0.115-0.972,0.217
				c-0.025,0.009-0.052,0.018-0.08,0.028c-0.002-0.017-0.003-0.034-0.005-0.051c-0.05-0.52-0.101-1.058-0.47-1.437
				c-0.398-0.408-1.055-0.627-2.008-0.667c-0.019-0.001-0.038-0.001-0.057-0.001c-0.365,0-0.672,0.143-0.942,0.27
				c-0.142,0.067-0.277,0.13-0.381,0.151c-2.84,0.602-5.725,1.608-8.618,1.341c-1.448-0.133-2.962,0.239-4.419,0.239
				c-0.591,0-1.214-0.063-1.802-0.014c-0.714,0.059-1.315,0.305-2.041,0.294c-1.536-0.023-3.242,0.232-4.705,0.717
				c-0.954,0.316-1.49,0.985-1.693,1.956c-0.108,0.514-0.121,1.048-0.131,1.572c-0.005,0.277,0.074,0.677-0.001,0.939
				c-0.117,0.407-0.46,0.47-0.739,0.82c-0.986,1.238,0.084,2.029,1.193,2.365c-0.158,0.669-0.33,1.344-0.502,2
				c-0.356,1.353-0.724,2.753-0.939,4.169c-0.096,0.632-0.232,1.281-0.364,1.909c-0.18,0.856-0.355,1.729-0.454,2.621
				c-0.053,0.042-0.112,0.077-0.165,0.121c-0.373,0.305-0.424,0.456-0.686,0.81c-0.266,0.359-0.38,0.206-0.495,0.692
				c-0.007,0.029-0.085,0.834-0.084,0.882c0.002,0.535,0.471,0.88,0.915,1.151c-0.246,0.671-0.499,1.363-0.667,2.083
				c-0.646,2.756-1.088,4.873-1.433,6.863c-0.175,1.006-0.281,2.243,0.311,3.232c-0.016,0.02-0.031,0.04-0.047,0.061
				c-0.3,0.388-0.64,0.827-0.59,1.358c0.059,0.63,0.666,1.678,1.445,1.819c0.832,0.151,1.691,0.164,2.431,0.164
				c0.12,0,0.239,0,0.359-0.001c0.118,0,0.236-0.001,0.354-0.001c0.115,0,0.231,0,0.346,0.001l0.028,0
				c0.251,0,0.485-0.031,0.712-0.061c0.193-0.025,0.375-0.049,0.539-0.049c0.048,0,0.093,0.002,0.134,0.006
				c0.753,0.076,1.52,0.176,2.263,0.272c0.778,0.101,1.582,0.206,2.381,0.285c1.027,0.101,2.069,0.177,3.077,0.251
				c0.738,0.054,1.5,0.11,2.248,0.175c0.226,0.02,0.407,0.029,0.573,0.029c1.281,0,1.772-0.646,1.932-1.332l0.922-0.239l2.688-0.697
				l0.305-0.079l0.125-0.289c0.083-0.192,0.176-0.4,0.274-0.618c0.266-0.591,0.568-1.262,0.815-1.966
				c0.142-0.403,0.356-1.013,0.075-1.571c-0.113-0.225-0.356-0.528-0.872-0.688l-0.305-0.095c-0.937-0.294-1.907-0.597-2.912-0.72
				c-1.599-0.194-3.226-0.344-4.799-0.489c-1.066-0.098-2.169-0.2-3.25-0.314c-0.476-0.05-0.964-0.159-1.48-0.273
				c-0.036-0.008-0.072-0.016-0.108-0.024c0.126-0.417,0.244-0.812,0.313-1.233c0.222-1.358,0.438-2.727,0.65-4.09
				c0.029-0.003,0.058-0.003,0.087-0.006c1.652-0.184,3.274-0.125,4.907-0.504c1.306-0.303,2.58-0.663,3.913-0.839
				c1.711-0.226,4.567,0.066,5.898-1.263c0.342-0.341,0.511-0.868,0.59-1.334c0.071-0.419-0.232-0.796-0.9-1.123
				c0.065-0.033,0.13-0.065,0.197-0.098c0.966-0.481,2.143-1.091,1.981-2.368c-0.07-0.55-0.428-1.019-0.849-1.353
				c-0.292-0.231-0.609-0.416-0.986-0.454c-0.7-0.071-1.359,0.366-1.97,0.638c-0.986,0.44-1.912,0.594-2.992,0.569
				c-1.317-0.03-2.733,0.222-4.032,0.023c-0.851-0.131-1.795-0.304-2.604-0.582c-0.482-0.166-0.891-0.162-1.294-0.097
				c0.219-0.906,0.372-1.84,0.343-2.828c-0.017-0.577,0.127-1.184,0.28-1.826c0.083-0.351,0.169-0.714,0.231-1.081l0.058-0.35
				c0.175-0.028,0.34-0.068,0.487-0.112c0.136-0.041,0.265-0.08,0.365-0.088c0.771-0.064,1.559-0.114,2.322-0.162
				c1.459-0.093,2.967-0.188,4.448-0.385c2.126-0.282,4.276-0.623,6.356-0.954c1.546-0.246,3.146-0.5,4.719-0.727
				c0.054-0.008,0.107-0.015,0.159-0.022c0.466-0.062,0.993-0.132,1.332-0.576c0.217-0.285,0.296-0.662,0.233-1.12
				c-0.14-1.02-0.842-1.629-1.879-1.629c-0.052,0-0.105,0.001-0.159,0.004l0.098-0.427L340.063,142.51z"
    />
    <path
      d="M179.377,153.788c-0.462-0.132-1.138,0.048-1.301-0.404c-0.039-0.109-0.03-0.229-0.019-0.345
				c0.062-0.692,0.124-1.385,0.186-2.077c0.019-0.207,0.031-0.436-0.094-0.602c-0.083-0.109-0.211-0.171-0.335-0.228
				c-0.663-0.307-1.391-0.604-2.104-0.445c-0.724,0.161-1.264,0.757-1.705,1.354c-1.411,1.911-2.288,3.878-3.832,5.746
				c-0.244,0.296-0.489,0.591-0.735,0.886c-0.333,0.399-0.668,0.796-1.002,1.193c-0.747,0.887-1.52,1.804-2.262,2.725
				c-0.436,0.541-0.86,1.086-1.29,1.632c-2.121-4.209-4.233-8.424-6.397-12.619c-0.295-0.571-0.749-1.232-1.387-1.156
				c-1.055,0.126-0.969,1.951-1.953,2.35c-0.267,0.108-0.586,0.092-0.819,0.262c-0.701,0.512,0.251,1.669-0.079,2.473
				c-0.68,0.457-1.803-0.294-2.347,0.318c-0.345,0.388-0.13,0.994,0.101,1.46c2.339,4.73,4.746,9.429,7.126,14.13
				c-0.145,0.177-0.291,0.365-0.436,0.542c-2.375,2.876-4.759,5.727-6.571,7.891c-0.791,0.944-1.597,1.875-2.42,2.791
				c-0.21,0.233-0.363,0.386-0.477,0.664c-0.185,0.453-0.377,0.763-0.713,1.133c-0.476,0.524-0.673,0.868-0.669,1.576
				c0.001,0.268-0.048,0.531,0.04,0.708c0.055,0.11,0.164,0.187,0.373,0.21c0.407,0.044,0.601-0.263,1.049-0.149
				c0.031,0.298,0.09,0.61,0.19,0.889c0.122,0.341,0.256,0.298,0.503,0.564c0.307,0.332,0.039,0.542-0.014,0.91
				c-0.031,0.218-0.095,0.887-0.006,1.091c0.158,0.359,1.49,0.167,1.791-0.003c0.263-0.149,0.454-0.412,0.725-0.547
				c0.569-0.284,0.562-0.049,0.832,0.358c2.138-0.388,3.203-2.385,4.478-3.931c0.319-0.387,0.649-0.788,0.979-1.172
				c1.359-1.585,2.896-3.376,4.452-5.184c0.802,1.598,1.646,3.202,2.463,4.754c0.255,0.483,0.477,1.017,0.793,1.464
				c0.134,0.189,0.302,0.343,0.412,0.549c0.373,0.704,0.56,1.577,0.843,2.326c0.285,0.756,0.477,1.545,0.822,2.277
				c0.196,0.416,0.493,0.731,0.703,1.129c0.208,0.395,0.208,0.816,0.298,1.24c0.013,0.063,0.029,0.126,0.047,0.189
				c0.162,0.557,0.253,1.09,0.375,1.653c0.101,0.466,0.396,0.867,0.567,1.306c0.142,0.363,0.17,0.76,0.317,1.119
				c0.137,0.335,0.412,0.754,0.735,0.94c0.339,0.195,0.945,0.348,1.329,0.37c0.505,0.029,0.783,0.038,0.826-0.501
				c0.067-0.85,1.156-0.626,1.49-1.358c0.258-0.567,0.159-1.218,0.162-1.821c0.001-0.204-0.041-0.357,0.088-0.523
				c0.571-0.737,2.006,0.142,2.705,0.097c0.671-0.044,0.51-0.828,0.51-1.338c-0.001-0.971-0.015-1.945-0.405-2.852
				c-0.233-0.543-0.392-1.095-0.585-1.655c-0.161-0.467-0.412-0.893-0.566-1.361c-0.169-0.512-0.452-1.105-0.83-1.499
				c-0.374-0.391-0.953-0.57-1.31-0.991c-0.539-0.637-0.837-1.607-1.049-2.399c-0.696-2.588-1.947-5.068-3.271-7.384
				c-0.263-0.46-0.534-0.935-0.781-1.406c-0.321-0.613-0.638-1.233-0.957-1.852c0.033-0.065,0.068-0.131,0.1-0.196
				c0.248-0.498,0.482-0.969,0.782-1.374c0.558-0.754,1.182-1.492,1.786-2.206c0.527-0.623,1.072-1.267,1.581-1.93
				c0.641-0.835,1.259-1.703,1.856-2.542c0.563-0.79,1.144-1.607,1.739-2.387c0.631-0.828,1.319-1.646,1.985-2.436
				c0.804-0.955,1.167-1.544,1.329-2.821C180.195,154.733,179.984,153.962,179.377,153.788z"
    />
    <path
      d="M209.957,255.189c0.61-1.065-0.43-2.472-1.642-2.668c-1.211-0.196-2.397,0.419-3.43,1.082
		c-0.075-0.541-0.709-0.854-1.25-0.781s-1,0.418-1.433,0.752c-2.31,1.782-4.059,3.98-6.333,5.752
		c-2.082,1.891-4.545,4.121-7.03,6.325c-0.88,0.781-1.774,1.559-2.656,2.328c0.017-0.23,0.034-0.46,0.051-0.69
		c0.087-1.156,0.177-2.352,0.24-3.533c0.067-1.246,0.116-2.511,0.164-3.734c0.035-0.884,0.071-1.799,0.112-2.696
		c0.088-1.887,0.177-3.733,0.297-5.563c0.237-3.626-0.065-7.644,0.951-11.104c1.208-4.117,2.433-8.231,3.32-12.432
		c0.105-0.497,0.205-1.018,0.067-1.507c-0.135-0.483-0.485-0.874-0.716-1.319c-0.786-1.514-1.063-4.354-3.452-2.816
		c-0.548,0.353-0.655,1.352-1.344,1.487c-0.973,0.192-1.245-1.337-2.359-0.585c-0.383,0.258-0.626,0.674-0.84,1.084
		c-1.28,2.449-1.569,5.414-2.144,8.112c-0.259,1.218-0.528,2.478-0.724,3.737c-0.174,1.114-0.247,2.247-0.318,3.344
		c-0.032,0.501-0.066,1.018-0.108,1.523c-0.219,2.603-0.474,5.629-0.731,8.6c-0.02,0.234-0.05,0.468-0.082,0.716
		c-0.05,0.388-0.102,0.79-0.114,1.202l-0.011,0.371c-0.04,1.333-0.081,2.712-0.03,4.075c0.02,0.52,0.103,1.027,0.184,1.517
		c0.09,0.549,0.176,1.068,0.17,1.572c-0.011,0.938-0.086,1.902-0.158,2.834c-0.063,0.813-0.128,1.655-0.153,2.49
		c-0.031,1.052-0.025,2.117-0.018,3.147c0.001,0.112-0.002,0.229-0.002,0.342c-1.377-1.048-2.764-2.119-4.107-3.159
		c-1.615-1.25-3.284-2.542-4.943-3.794c-1.016-0.766-2.091-1.471-3.132-2.152c-0.303-0.198-0.606-0.397-0.908-0.597
		c-0.204-0.135-0.42-0.234-0.61-0.321c-0.188-0.086-0.365-0.167-0.49-0.264c-0.571-0.443-1.144-0.909-1.698-1.359
		c-0.761-0.618-1.548-1.258-2.352-1.858c-0.8-0.597-1.62-0.767-2.308-0.478c-0.458,0.192-0.807,0.564-1.021,1.077
		c-0.151-0.048-0.315-0.081-0.49-0.079c-0.363,0.003-0.694,0.109-0.986,0.202c-0.109,0.035-0.213,0.068-0.311,0.094l-0.781,0.204
		l0.373,0.716c0.044,0.086,0.088,0.197,0.134,0.314c0.034,0.086,0.069,0.175,0.108,0.265c-0.089,0-0.174,0.003-0.255,0.007
		c-0.103,0.004-0.2,0.008-0.276,0.003l-0.851-0.054l0.181,0.833c0.014,0.064,0.023,0.165,0.033,0.263
		c0.032,0.337,0.068,0.719,0.356,0.986c0.038,0.035,0.079,0.067,0.122,0.094c0.174,0.11,0.339,0.238,0.503,0.39
		c0.434,0.404,0.785,0.906,1.155,1.438c0.393,0.564,0.8,1.147,1.336,1.645c0.23,0.214,0.464,0.394,0.714,0.55
		c0.288,0.18,0.559,0.422,0.846,0.678c0.365,0.326,0.741,0.662,1.2,0.913c3.024,1.655,5.593,4.603,8.313,6.73
		c3.829,2.994,7.843,5.717,11.871,8.419c0.12,0.211,0.272,0.411,0.47,0.59c0.088,0.08,0.18,0.152,0.273,0.214
		c0.29,0.193,0.598,0.293,0.911,0.306c0.594,0.398,1.189,0.796,1.782,1.196c0.525,0.353,1.19,0.384,1.737,0.081
		c0.536-0.298,0.856-0.864,0.833-1.478c-0.018-0.5-0.309-0.929-0.542-1.273c-0.039-0.058-0.078-0.116-0.115-0.172
		c-0.02-0.031-0.046-0.051-0.068-0.081c1.379-0.659,2.519-1.636,3.594-2.657c0.227-0.215,0.451-0.432,0.673-0.648
		c0.187-0.182,0.374-0.363,0.562-0.542c6.207-5.928,13.525-10.653,19.738-16.68c0.578-0.56,1.182-1.186,1.295-1.983
		C211.419,255.935,210.74,255.001,209.957,255.189z"
    />
    <path
      d="M157.018,25.824c0.274,0.594-0.048,2.119,0.243,3.088c0.109,0.361,0.249,0.766,0.56,0.944
		c0.252,0.144,0.537,0.092,0.809,0.133c0.559,0.085,1.079,0.629,1.189,1.242c1.627-0.036,2.641-1.642,3.843-2.715
		c1.086-0.97,2.43-1.546,3.678-2.269c3.675-2.129,6.798-6.182,9.927-9.138c0.166-0.157,0.4-0.277,0.646-0.403
		c0.121-0.062,0.247-0.126,0.371-0.198c0.356-0.207,0.723-0.481,0.994-0.936c-0.145,1.775-0.272,3.463-0.377,5.078
		c-0.074,1.141-0.124,2.299-0.172,3.419c-0.061,1.432-0.1,5.913-0.215,7.36c-0.268,3.358-0.541,10.334-0.903,13.099
		c-0.225,1.715-0.567,3.454-0.899,5.136c-0.095,0.481-0.189,0.961-0.282,1.442c-0.058,0.304-0.158,0.621-0.264,0.957
		c-0.222,0.705-0.452,1.435-0.335,2.185c0,0,0.015,0.097,0.016,0.099c0.127,0.809-0.183,1.969-0.099,2.95
		c0.058,0.681,0.305,1.275,1.02,1.605c0.524,0.242,1.162,0.122,1.651-0.185c0.489-0.307,0.852-0.777,1.167-1.261
		c0.589,0.69,1.664,0.908,2.475,0.501c0.811-0.407,1.279-1.399,1.077-2.284c0.609,0.497,1.711,0.409,1.997-0.323
		c0.202-0.516-0.061-1.082-0.203-1.618c-0.453-1.712,0.036-3.837,0.009-5.606c-0.027-1.796,0.515-3.63,0.726-5.414
		c0.257-2.171,0.458-8.378,0.684-10.514c0.223-2.097,0.428-7.266,0.679-9.395c0.103-0.877,0.257-1.773,0.407-2.639
		c0.184-1.067,0.373-2.17,0.476-3.276c0.031-0.329,0.036-0.659,0.048-0.988c0.011,0.016,0.024,0.032,0.036,0.048
		c0.335,0.48,0.664,0.984,0.982,1.471c0.514,0.788,1.046,1.603,1.637,2.368c0.539,0.699,1.125,1.368,1.692,2.015
		c0.679,0.775,1.38,1.577,1.983,2.41c1.575,2.181,3.412,4.787,5.092,7.582c0.785,1.305,1.424,2.394,2.459,3.492
		c0.638,0.677,1.183,2.461,2.308,2.385c0.114-0.008,0.233-0.034,0.36-0.083c1.265-0.488,1.508-2.575,1.862-3.607
		c0.36-1.048,0.628-0.358,1.288-0.842c0.528-0.387,0.364-1.365,0.241-1.9c-0.233-1.017-0.834-1.9-1.513-2.674
		c-0.344-0.392-0.728-0.737-1.095-1.105c-0.928-0.928-1.422-2.256-2.063-3.409c-0.315-0.566-0.628-1.134-0.94-1.702
		c-1.108-2.013-2.254-4.094-3.493-6.082c-1.014-1.628-2.137-3.214-3.223-4.748c-0.498-0.703-1.013-1.431-1.509-2.15
		c-0.165-0.24-0.332-0.479-0.499-0.719c-0.519-0.746-1.056-1.517-1.544-2.292c-0.432-0.685-0.859-1.393-1.271-2.078
		c-0.644-1.069-1.31-2.174-2.02-3.234c-0.586-0.875-1.728-1.414-2.776-0.75c-0.076,0.048-0.145,0.107-0.207,0.176
		c-0.254,0.283-0.326,0.676-0.385,1.028c-0.139-0.065-0.293-0.113-0.465-0.122c-0.399-0.021-0.783,0.056-1.122,0.123
		c-0.133,0.026-0.258,0.052-0.38,0.07l-0.766,0.119l0.257,0.731c0.019,0.054,0.036,0.108,0.054,0.162
		c-0.087,0.018-0.173,0.036-0.264,0.065c-0.097,0.031-0.444-0.068-0.745-0.286c-0.503-0.365-1.549-1.124-2.565-0.105
		c-0.035,0.036-0.185,0.078-0.283,0.107c-0.128,0.037-0.273,0.079-0.416,0.15c-0.078,0.039-0.16,0.075-0.242,0.111
		c-0.308,0.136-0.657,0.29-0.911,0.599c-0.706,0.857-1.408,1.741-2.088,2.596c-0.826,1.04-1.681,2.115-2.544,3.145
		c-0.379,0.452-0.818,0.843-1.282,1.258c-0.272,0.242-0.553,0.493-0.822,0.758c-0.212,0.208-0.406,0.431-0.593,0.646
		c-0.183,0.21-0.356,0.408-0.54,0.59c-0.255,0.251-0.54,0.473-0.842,0.708l-0.073,0.057c-0.109,0.008-0.274,0.039-0.411,0.186
		c-0.219,0.234-0.435,0.476-0.644,0.709c-0.334,0.373-0.679,0.759-1.032,1.11c-0.177,0.176-0.384,0.332-0.603,0.497
		c-0.218,0.164-0.443,0.334-0.654,0.536c-0.301,0.288-0.586,0.594-0.862,0.891c-0.243,0.261-0.495,0.531-0.749,0.779
		c-0.143,0.139-0.318,0.259-0.54,0.41c-0.115,0.078-0.245,0.167-0.383,0.267l-0.158,0.115l-0.046,0.207
		c-0.026,0.118-0.039,0.244-0.053,0.378c-0.017,0.166-0.049,0.474-0.115,0.548c-0.461,0.512-1.002,0.938-1.574,1.389
		c-0.029,0.023-0.058,0.046-0.087,0.069l-0.17-0.205l-0.09,0.081c-0.009-0.007-0.32,0.283-0.32,0.283l-0.18,0.167
		c-0.21,0.189-0.417,0.377-0.623,0.563c-0.644,0.582-1.252,1.133-1.878,1.68C157.285,23.879,156.455,24.604,157.018,25.824z"
    />
  </StyledSvg>
);

export default ScrollHere;
