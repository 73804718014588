import styled from 'styled-components';
import CSSParallax from '../../components/CSSParallax';
import theme from '../../styles/theme';
import StarsImage from '../../assets/images/stars-white-sparse.png';
import ThemeType from '../../types/Theme';

export const StyledSection = styled(CSSParallax.Section)(
  ({ theme: { spacing, mobileView } }: ThemeType) => `
    background-color: ${theme.colors.lightBlack};
    background-image: url(${StarsImage});
    background-repeat: repeat-y;
    background-position: center top;
    background-size: 100% auto;
    color: ${theme.colors.white};
    position: relative;
    z-index: 4;
    height: 100vh;
    // padding: 0 ${spacing.x8};

    ${mobileView} {
      padding: 0 ${spacing.x2};
      height: auto;
    }
`,
);

export const StyledContent = styled(CSSParallax.Content)`
  padding-top: 0 !important;
`;

interface ContentInnerType extends ThemeType {
  pageHasAnnouncement: boolean;
}

export const StyledContentInner = styled(CSSParallax.ContentInner)(
  ({ theme: { mobileView }, pageHasAnnouncement }: ContentInnerType) => `
    ${pageHasAnnouncement ? 'padding-top: 60px;' : ''}
    height: 100vh;
    overflow: hidden;

    ${mobileView} {
      height: auto;
      overflow: visible;
    }
`,
);
