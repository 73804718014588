import React from 'react';
import ReactDOM from 'react-dom';
import { format } from 'date-fns';
import Artist from '../../components/ScrollArtist';
import Filter from '../../components/Filter';
import Lineup from '../../components/Lineup';
import SideBySide from '../../components/SideBySide';
import FixedPage from '../../layout/components/FixedPage';
import FixedSection from '../../sections/Fixed';
import { ArtistDetailsSection, LineupSection } from '../../styles/components/LineupLayout';
import Image from '../../types/Image';
import isMobile from '../../utils/isMobile';
import { ScrollHere } from '../../components/Lineup/styles';
import ScrollHereSvg from '../../assets/svg/scroll-here';
import theme from '../../styles/theme';
import isBrowser from '../../utils/isBrowser';
import isHeadless from '../../utils/isHeadless';
import onReady from '../../utils/onReady';
import onReadyUnmount from '../../utils/onReadyUnmount';
import { get as getLocalStorage } from '../../utils/localStorage';
import { get as getSessionStorage, set as setSessionStorage } from '../../utils/sessionStorage';
import MySplendourModal from '../../components/MySplendourModal';
import { InView } from 'react-intersection-observer';
import { MODALS, TOGGLE_OPTIONS } from '../../constants';
import API from '../../agents/API';
// import 'intersection-observer';

const generateArtistId = (id) => `Artist-${id}`;

class LineupPage extends FixedPage {
  static getDerivedStateFromProps({ content, context, announcement }, prevState) {
    const derivedState = {
      ...prevState,
      headless: isHeadless(),
    };

    if (announcement) {
      derivedState.announcement = announcement;
    }

    if (content && content.artists) {
      const selectedOption = prevState.selectedOption;

      if (selectedOption === TOGGLE_OPTIONS.ALL) {
        derivedState.artists = content.artists;
      } else if (selectedOption === TOGGLE_OPTIONS.MY_SPLENDOUR) {
        const user = getSessionStorage('user');

        derivedState.artists =
          user && user.artists ? content.artists.filter(({ wordpress_id }) => user.artists.includes(wordpress_id)) : [];
      } else {
        derivedState.artists = content.artists.filter(
          ({ data: { sessions } }) => sessions.length && sessions.find(({ sessionDate }) => format(sessionDate, 'dddd') === selectedOption),
        );
      }
    }

    if (!prevState.hasInteracted && context.selectedArtistWordpressId) {
      derivedState.isOpen = true;
    }

    return derivedState;
  }

  state = {
    artists: [],
    announcement: null,
    selectedOption: TOGGLE_OPTIONS.ALL,
    artistOffset: 0,
    lineupOffset: 0,
    hasInteracted: false,
    isOpen: false,
    loaded: false,
    headless: false,
    showModal: false,
  };

  private headerRef;

  constructor(props) {
    super(props);

    this.determineOffsets = this.determineOffsets.bind(this);
    this.handleInteraction = this.handleInteraction.bind(this);
    this.handleInView = this.handleInView.bind(this);
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.headerRef = React.createRef();
    this.handleLoad = this.handleLoad.bind(this);
    this.initOffsets = this.initOffsets.bind(this);
    this.hideAnnouncement = this.hideAnnouncement.bind(this);
    this.pageHasAnnouncement = this.pageHasAnnouncement.bind(this);
  }

  componentDidMount() {
    onReady(this.handleLoad);
    onReady(this.initOffsets);
    onReady(this.setUserSessionData);
  }

  initOffsets() {
    this.determineOffsets();

    window.addEventListener('resize', this.determineOffsets);
  }

  componentWillUnmount() {
    if (isBrowser()) {
      window.removeEventListener('resize', this.determineOffsets);
    }

    onReadyUnmount(this.handleLoad);
    onReadyUnmount(this.initOffsets);
    onReadyUnmount(this.setUserSessionData);
  }

  handleArtistFilter = (selectedOption) => {
    window.history.pushState(null, '', `/${this.props.context.lineupPageSlug}/`);

    this.handleInteraction();

    this.setState({ selectedOption });
  };

  determineOffsets() {
    let artistOffset = 0;
    let lineupOffset = 0;

    const isMobileViewport = isMobile();

    if (!isMobileViewport && this.headerRef) {
      const element = ReactDOM.findDOMNode(this.headerRef.current);

      artistOffset = (element.offsetHeight + 45) * -1;
    }

    if (isBrowser()) {
      lineupOffset = (window.innerHeight / 2) * -1;
    }

    this.setState({
      lineupOffset,
      artistOffset,
    });
  }

  handleInteraction() {
    this.setState({
      hasInteracted: true,
    });
  }

  open() {
    this.setState({
      hasInteracted: true,
      isOpen: true,
    });
  }

  close() {
    window.history.pushState(null, '', `/${this.props.context.lineupPageSlug}/`);

    this.setState({
      hasInteracted: true,
      isOpen: false,
    });
  }

  handleInView(inView) {
    if (inView) {
      window.history.pushState(null, '', `/${this.props.context.lineupPageSlug}/`);

      const artistLinks = document.getElementsByClassName('lineup-link-active');

      Array.prototype.forEach.call(artistLinks, (artistLink) => {
        artistLink.classList.remove('lineup-link-active');
      });
    }
  }

  async setUserSessionData() {
    const token = getLocalStorage('token');
    const user = getSessionStorage('user');

    if (token && !user) {
      const { data } = await API.getContact({ token });
      setSessionStorage('user', JSON.stringify(data));
    }
  }

  renderChildren() {
    const {
      content,
      context: { selectedArtistWordpressId },
    } = this.props;

    if (content && content.artists) {
      const { artists, selectedOption, hasInteracted, showModal } = this.state;
      const filteredArtists = artists.filter((artist) => !artist.data.hideFromLineup);
      return (
        <>
          <MySplendourModal
            updateParent={() => this.renderChildren()}
            visible={String(showModal) === MODALS.MYSPLENDOUR}
            close={() => this.setState({ showModal: null })}
          />
          <FixedSection isFirstSection={true} pageHasAnnouncement={this.pageHasAnnouncement()} natural={true}>
            <SideBySide.Header ref={this.headerRef}>
              <Filter.ToggleButtonsHeader
                title={content.title}
                options={Object.entries(TOGGLE_OPTIONS).map(([, value]) => value)}
                selectedOption={selectedOption}
                onChange={this.handleArtistFilter}
              />
            </SideBySide.Header>
            <SideBySide.Container>
              <SideBySide.Left close={this.close} isOpen={this.state.isOpen} id="artists-container">
                <InView threshold={0.2} as="div" onChange={this.handleInView}>
                  <ScrollHere>
                    <ScrollHereSvg color={theme.colors.babyPink} />
                  </ScrollHere>
                </InView>
                <ArtistDetailsSection>
                  {filteredArtists.map(
                    (artist: { wordpress_id: string; title: string; slug: string; featuredImage: Image; data: any }, index) => (
                      <div key={index} id={generateArtistId(artist.wordpress_id)}>
                        <Artist
                          {...artist}
                          lineupOffset={this.state.lineupOffset}
                          artistOffset={this.state.artistOffset}
                          containerId="artists-container"
                          selected={!hasInteracted && artist.wordpress_id === selectedArtistWordpressId}
                          showSubscribeModal={() => this.setState({ showModal: MODALS.MYSPLENDOUR })}
                        />
                      </div>
                    ),
                  )}
                </ArtistDetailsSection>
              </SideBySide.Left>
              <SideBySide.Right id="links-container">
                <LineupSection>
                  <Lineup
                    artists={filteredArtists}
                    align="left"
                    artistOffset={this.state.artistOffset}
                    containerId="artists-container"
                    onArtistClick={() => {
                      this.open();
                      this.handleInteraction();
                    }}
                  />
                </LineupSection>
              </SideBySide.Right>
            </SideBySide.Container>
          </FixedSection>
        </>
      );
    }
    return <div />;
  }
}

export default LineupPage;
