import React from 'react';
import Overspray from '../../components/Overspray';
import { StyledContentInner, StyledContent, StyledSection } from './styles';

const Fixed: React.FunctionComponent<any> = ({ children, isFirstSection, pageHasAnnouncement, natural }) => (
  <StyledSection natural={natural}>
    <StyledContent isFirstSection={isFirstSection} pageHasAnnouncement={pageHasAnnouncement} natural={natural}>
      <Overspray height="50vh" />
      <StyledContentInner pageHasAnnouncement={pageHasAnnouncement}>{children}</StyledContentInner>
    </StyledContent>
  </StyledSection>
);

export default Fixed;
