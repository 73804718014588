import React from 'react';
import styled from 'styled-components';
import ThemeType from '../../../types/Theme';

interface OuterProps extends ThemeType {
  isOpen?: boolean;
}

const Backdrop = styled.div(
  ({ isOpen, theme: { mobileView } }: OuterProps) => `
    display: none;

    ${mobileView} {
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 99;
      opacity: 0;
      pointer-events: none;
      background-color: rgba(0, 0, 0, 0.65);
      transition: all 0.2s ease-in-out;

      ${
        isOpen
          ? `
            opacity: 1;
            pointer-events: all
          `
          : ''
      }
    }
`,
);

const LeftOuter = styled.div(
  ({ isOpen, theme: { mobileView, colors } }: OuterProps) => `
    flex: 1;
    height: 100%;
    max-width: 50%;
    overflow-y: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* IE 10+ */

    ::-webkit-scrollbar {
      width: 0;
      height: 0;
      display: none;
    }

    ${mobileView} {
      background-color: ${colors.green};
      color: ${colors.black};
      position: fixed;
      top: 0;
      right: 0;
      left: auto;
      width: 88vw;
      max-width: none;
      z-index: 100;
      will-change: transform;
      transform: translateX(88vw);
      transition: transform 0.2s ease-in-out;
      pointer-events: none;

      ${
        isOpen
          ? `
            transform: translateX(0);
            pointer-events: all;
          `
          : ''
      }
    }
`,
);

const LeftInner = styled.div(
  ({ theme: { mobileView, screenWidths, spacing } }: ThemeType) => `
    max-width: ${screenWidths.tablet / 2}px;
    margin: 0 0 0 auto;
    padding: ${spacing.x6} 0 ${spacing.x16};

    ${mobileView} {
      padding: ${spacing.x12} ${spacing.x3} ${spacing.x16};
    }
`,
);

interface LeftProps {
  id?: string;
  children?: React.ReactNode;
  isOpen?: boolean;
  close?: () => void;
}

const Left: React.StatelessComponent<LeftProps> = ({ children, id, isOpen, close }) => {
  return (
    <>
      <Backdrop isOpen={isOpen} onClick={close} />
      <LeftOuter isOpen={isOpen} id={id}>
        <LeftInner>{children}</LeftInner>
      </LeftOuter>
    </>
  );
};

export default Left;
