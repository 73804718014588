import React from 'react';
import styled from 'styled-components';
import ThemeType from '../../../types/Theme';

const HeaderOuter = styled.div(
  ({ theme: { spacing, mobileView } }: ThemeType) => `
    display: flex;
    flex-direction: row;
    position: relative;
    width: 100%;
    padding: 0 ${spacing.x8};

    ${mobileView} {
      padding: 0 ${spacing.x2};
    }
`,
);

const HeaderInner = styled.div(
  ({ theme: { colors, mobileView, width } }: ThemeType) => `
    width: 100%;
    max-width: ${width.tablet};
    margin: 0 auto;
    position: relative;

    &:after {      
      content: '';
      display: block;
      position: absolute;
      top: auto;
      bottom: 0;
      width: 100%;
      left: 50%;
      max-width: ${width.tablet};
      border-bottom: 2px solid ${colors.green};
      opacity: 0.5;
      height: 2px;
      transform: translateX(-50%);
      border-radius: 10px;

      ${mobileView} {
        display: none;
      }
    }
`,
);

class Header extends React.PureComponent {
  render() {
    const { children } = this.props;

    return (
      <HeaderOuter>
        <HeaderInner>{children}</HeaderInner>
      </HeaderOuter>
    );
  }
}

export default Header;
